import { Navigate, Outlet, useLocation } from "react-router-dom";

const Private = () => {
  const location = useLocation();
  const currentUrl = location.pathname;

  const auth = localStorage.getItem("token");

  localStorage.setItem("redirectLocation", currentUrl);

  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default Private;
