import React, { useState } from "react";
import { MenuItem, ListItemIcon } from "@mui/material";

import UserIcon from "../../styles/svg/user-icon.svg";
import FormDialog from "../Dialog/FormDialog";
import UpdateUser from "./UpdateUser";

function Profile({ Close }) {
  const [anchorEl, setAnchorEl] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));

  const handleClose = () => {
    setAnchorEl(false);
    Close();
  };
  const handleClick = () => {
    setAnchorEl(true);
  };
  return (
    <>
      {anchorEl && (
        <FormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Edit Inspector"}
          btnTitle={"Confirm"}
        >
          <UpdateUser onClose={handleClose} data={userData} />
        </FormDialog>
      )}
      <MenuItem onClick={handleClick}>
        <ListItemIcon>
          <img src={UserIcon} />
        </ListItemIcon>
        <p className="logout-text"> Profile</p>
      </MenuItem>
    </>
  );
}

export default Profile;
