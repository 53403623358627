import React, { useState } from "react";
import editIcon from "../../../styles/svg/edit-icon.svg";
import FormDialog from "../../../components/Dialog/FormDialog";
import EditInspector from "./EditInspector";
import Switch from "../../services/components/Switch";
import { updateInspector } from "../../../sevices/inspectorApi";
import useCustomMutate from "../../../hooks/useMutate";

function EditIcon({ params }) {
  const { mutate } = useCustomMutate(updateInspector, ["inspector"]);
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };

  const changeStatus = (status) => {
    mutate({ id: params.row.inspector_id, data: { has_permission: status } });
  };
  return (
    <>
      {anchorEl && (
        <FormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Edit Inspector"}
          btnTitle={"Confirm"}
        >
          <EditInspector onClose={handleClose} data={params.row} />
        </FormDialog>
      )}
      <div className="d-flex">
        <img
          src={editIcon}
          className="mr-2 cursor-pointer "
          onClick={handleClick}
        />
        <Switch
          callback={changeStatus}
          defaultValue={params.row.has_permission}
        />
      </div>
    </>
  );
}

export default EditIcon;
