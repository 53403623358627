import React, { useState } from "react";
import { Alert, Box, Button, Stack, TextField, Grid } from "@mui/material";

import useCustomForm from "../../../hooks/useForm";
import useCustomMutate from "../../../hooks/useMutate";
import { createService, updateService } from "../../../sevices/serviceApi";
import Switch from "./Switch";

function CreateService({ onClose, isEdit, item }) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, onSubmit, handleReset, setValue } =
    useCustomForm(
      isEdit
        ? { name: item.name, is_active: item.is_active }
        : { is_active: true }
    );

  const callback = () => {
    setLoading(false);
    onClose();
  };
  const { mutate: createServiceApi } = useCustomMutate(
    createService,
    ["service"],
    callback
  );
  const { mutate: updateServiceApi } = useCustomMutate(
    updateService,
    ["service"],
    onClose
  );

  const submit = (data) => {
    /**
     * Create Service
     */
    //    setApiError("");
    setLoading(true);
    console.log("data", data);
    isEdit
      ? updateServiceApi({ data, id: item.service_id })
      : createServiceApi(data);
  };
  const changeStatus = (status) => {
    setValue("is_active", status);
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(submit)}
      noValidate
      sx={{
        // padding: "14px",
        flexGrow: 1,
      }}
    >
      <Stack spacing={1}>
        {/* {apiError && <Alert severity="error">{apiError}</Alert>} */}
        <TextField
          label={"Name"}
          fullWidth
          {...register("name", {
            required: `Service Name is required`,
            maxLength: { value: 12, message: "Max limit is 12 letters" },
          })}
          error={!!errors?.name}
          helperText={errors?.name?.message}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#16304f", // Change this to your desired focus color
              },
            },
            "& .MuiInputLabel-root": {
              "&.Mui-focused": {
                color: "#16304f", // Change this to your desired label focus color
                fontFamily: "segoe_uisemibold",
                fontSize: "13px",
              },
            },
          }}
        />
        {isEdit ? (
          <Grid container className="status-grid">
            <Grid item xs={3} sm={2} md={2}>
              <p className="status-text">Status</p>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Switch callback={changeStatus} defaultValue={item.is_active} />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        <Stack
          sx={{
            alignSelf: "end",
          }}
          direction={"row"}
          spacing={2}
          className="popup-footer-border"
        >
          <Button
            variant="outlined"
            className="secondary-btn-class"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            // sx={{
            //   bgcolor: "#ED7047",
            //   ml: "10px",
            //   fontSize: "16px",
            //   padding: "8px 14px",
            //   fontWeight: "600",
            //   textTransform: "capitalize",
            //   minWidth: "130px",
            // }}
            className="primary-btn-class"
            disabled={loading}
            sx={{ boxShadow: "none" }}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default CreateService;
