import React, { useState } from "react";
import InspectorForm from "./InspectorForm";

function AddInspector({ onClose }) {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    phone_number: "",
    certificate: "",
    certificate_id: "",
    certification_expire_date: "",
    has_permission: "",
    address: "",
  });
  return (
    <div>
      <InspectorForm initialValues={formData} onClose={onClose} />
    </div>
  );
}

export default AddInspector;
