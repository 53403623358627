import React from "react";
import { Box, Grid, Stack } from "@mui/material";
// import Email from "./Email";
// import Export from "./Export";

function Heading() {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid item xs={8} md={4}>
            <p className="page-heading-name">Inspectors Schedule</p>
          </Grid>
          {/* <Grid className={`d-flex justify-content-end`} item xs={4} md={8}>
            <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 2 }}>
              <Email />
              <Export />
            </Stack>
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
}

export default Heading;
