import moment from "moment";
import ReportDownload from "../../../../Tickets/components/ReportDownload";
import ViewDocument from "./ViewDocument";

export const columns = [
  {
    field: "building_address",
    headerName: "Building",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 110,
    flex: 1.5,
    // editable: true,
  },

  {
    field: "apartment",
    headerName: "Unit",
    renderCell: (params) => {
      return <p className="unit-ticket">{params.value}</p>;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },

  {
    field: "service_type",
    headerName: "Type",
    renderCell: (params) => {
      return <p>{params.value}</p>;
    },
    minWidth: 130,
    flex: 1.2,
    // editable: true,
    sortable: false,
  },
  {
    field: "scheduled_date",
    headerName: "Date",
    renderCell: (params) => {
      return <p>{moment(params.value).format("MM/DD/YYYY")}</p>;
    },
    minWidth: 130,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "action",
    headerName: "Actions",
    renderCell: (params) => {
      return (
        <div className="d-flex">
          {/* <ViewDocument params={params} /> */}
          <ReportDownload params={params} idClient={true} />
        </div>
      );
    },
    minWidth: 100,
    flex: 0.9,
    // editable: true,
    sortable: false,
  },
];
