import React from "react";
import Heading from "./components/Heading";
import List from "./components/List";
import "./service.scss";

function Service() {
  return (
    <div>
      <Heading />
      <List />
    </div>
  );
}

export default Service;
