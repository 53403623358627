import React, { useEffect, useState } from "react";
import LongFormDialog from "../../../components/Dialog/LongFormDialog";
import { useTerms } from "../../../reactQuery/terms";

function ViewTerms() {
  const [anchorEl, setAnchorEl] = useState(false);
  const [value, setValue] = useState("");
  const { responseData } = useTerms();

  useEffect(() => {
    if (responseData) {
      setValue(responseData);
      //   console.log(responseData);
    }
  }, [responseData]);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };
  return (
    <div>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Terms"}
          btnTitle={"Confirm"}
        >
          <div
            dangerouslySetInnerHTML={{ __html: value }}
            className="mb-4"
          ></div>
        </LongFormDialog>
      )}
      <span className="underline orange-color " onClick={handleClick}>
        View Terms
      </span>
    </div>
  );
}

export default ViewTerms;
