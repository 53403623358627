import React, { useState } from "react";
import editIcon from "../../../styles/svg/edit-icon.svg";
import LongFormDialog from "../../../components/Dialog/LongFormDialog";
import EditClient from "./EditClient";
import CustomSelectDropdown from "../../../components/CustomSelectDropDown";
import PendingIcon from "../../../styles/svg/pending-icon.svg";
import ActiveIcon from "../../../styles/svg/active-icon.svg";
import SchedulingIcon from "../../../styles/svg/scheduling-blocked-icon.svg";
import AccountBlockedIcon from "../../../styles/svg/account-blocked-icon.svg";
import StatusDropDown from "./StatusDropDown";
import useCustomMutate from "../../../hooks/useMutate";
import { updateClient } from "../../../sevices/clientApi";
import ChangeStatus from "./ChangeStatus";

function Action({ params }) {
  const [anchorEl, setAnchorEl] = useState(false);
  const { mutate } = useCustomMutate(updateClient, ["clients"]);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };

  const changeStatus = (value) => {
    const data = { id: params.row.client_id, data: { status: value } };
    mutate(data);
  };

  const options = [
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={PendingIcon} className="mr-2" />
          Pending Approval
        </div>
      ),
      value: "Pending Approval",
    },
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={ActiveIcon} className="mr-2" />
          Active
        </div>
      ),
      value: "Active",
    },
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={SchedulingIcon} className="mr-2" />
          Scheduling Blocked
        </div>
      ),
      value: "Scheduling Blocked",
    },
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={AccountBlockedIcon} className="mr-2" />
          Account Locked
        </div>
      ),
      value: "Account Locked",
    },
  ];
  return (
    <div
      className="d-flex table-actions"
      key={`actions-id-${params.row.client_id}`}
    >
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Update Client"}
          btnTitle={"Confirm"}
        >
          <EditClient onClose={handleClose} data={params.row} />
        </LongFormDialog>
      )}
      <img
        src={editIcon}
        className="mr-30 cursor-pointer "
        onClick={handleClick}
      />
      <ChangeStatus
        options={options}
        // label="Filter by Days"
        onSelectChange={changeStatus}
        defaultValue={params.row.status}
      />
    </div>
  );
}

export default Action;
