import React, { useEffect, useState } from "react";
import { useCustomParams } from "../../../hooks/useCustomParams";
import { useDispatch } from "react-redux";
import { assignTickets } from "../../../redux/ticketAssignSlice";

function AssignTicket({ params }) {
  const [inspector, updateSearchParams, removeSearchParam] =
    useCustomParams("inspector");
  const [jobInspector, setJobInspector] = useState(params.row.inspector_id);
  const dispatch = useDispatch();

  useEffect(() => {
    setJobInspector(params.row.inspector_id);
  }, [params.row.inspector_id]);

  const onChange = (event) => {
    // callback?.(event.currentTarget.checked);
    if (event.currentTarget.checked) {
      setJobInspector(inspector);
      dispatch(
        assignTickets({
          inspector_id: inspector * 1,
          job_id: params.row.job_id,
        })
      );
    } else {
      setJobInspector(null);
      dispatch(
        assignTickets({
          inspector_id: null,
          job_id: params.row.job_id,
        })
      );
    }
    // event.currentTarget.checked
    //   ? setJobInspector(inspector)
    //   : setJobInspector(null);
  };
  return (
    <div
      className="w-100 d-flex justify-content-center"
      key={params.row.job_id}
    >
      <label class="custom-checkbox-large">
        <input
          type="checkbox"
          id={`assign-checkbox-${params.row.job_id}`}
          // key={`assign-checkbox-${params.row.job_id}`}
          onClick={onChange}
          // defaultChecked={inspector ? inspector == jobInspector : false}
          checked={inspector ? inspector == jobInspector : false}
          disabled={!inspector}
        />
        <span
          class="checkmark-large"
          style={{ backgroundColor: `${!inspector ? "#ebebeb" : ""}` }}
        ></span>
      </label>
    </div>
  );
}

export default AssignTicket;
