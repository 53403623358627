import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useCustomParams } from "../../../hooks/useCustomParams";
import CustomTable from "../../../components/Table/CustomTable";
import { useInspector } from "../../../reactQuery/inspector";
import Loader from "../../../components/Loader";
import { columns } from "./tableColumns";

function Table() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [date] = useCustomParams("date");
  const [search] = useCustomParams("search");
  const encryptDate = JSON.parse(date);

  const { isLoading, error, responseData, isError, refetch, totalRecords } =
    useInspector({
      page: page + 1,
      pageSize,
      search,
      date: encryptDate,
      pagination: true,
    });

  useEffect(() => {
    refetch();
  }, [page, pageSize, search, date]);

  console.log("error", error);
  return (
    <div className="assign-inspector-table">
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <CustomTable
          initialRows={responseData}
          columns={columns}
          // defaultSort={"building_address"}
          headerBgColor={"#F5F5F9"}
          rowCount={totalRecords}
          setPaginationModel={(data) => {
            setPage(data.page);
            setPageSize(data.pageSize);
          }}
          page={page}
          pageSize={pageSize}
          subHeight={324}
          // hideFooter={true}
        />
      )}
    </div>
  );
}

export default Table;
