import axios from "axios";

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "",
});

Api.interceptors.request.use(function (response) {
  const token = localStorage.getItem("token");
  if (token !== undefined && token !== null) {
    response.headers["authorization"] = `Bearer ${token}`;
  }
  return response;
});

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      console.log("inside - axios response interceptors with 401 status code");
      localStorage.clear();
      window.location =
        window.location.protocol + "//" + window.location.host + "/";
    }
    return Promise.reject(error);
  }
);

export default Api;
