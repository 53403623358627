import React, { useEffect, useState } from "react";

function Switch({ defaultValue, callback }) {
  const [status, setStatus] = useState(defaultValue);

  useEffect(() => {
    setStatus(defaultValue);
  }, [defaultValue]);
  return (
    <div className="long-switch">
      <label className="switch">
        <input
          type="checkbox"
          value={status}
          checked={status}
          defaultValue={defaultValue}
          onChange={(e) => {
            // setValue(name, !status);
            callback?.(!status);
            setStatus(!status);
            e.target.blur();
          }}
        />
        <div
          className={`slider d-flex align-items-center ${
            status ? "" : "justify-content-end"
          }`}
        >
          {status ? (
            <p className="clr-white ml-2">Enabled</p>
          ) : (
            <p className="clr-white mr-2">Disabled</p>
          )}
        </div>
      </label>
    </div>
  );
}

export default Switch;
