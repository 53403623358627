import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Button, Stack } from "@mui/material";
import "react-quill/dist/quill.snow.css"; // import styles
import PropTypes from "prop-types";
import useCustomMutate from "../../../hooks/useMutate";
import { updateTerms } from "../../../sevices/termsApi";
import { useTerms } from "../../../reactQuery/terms";

function Editor() {
  const [value, setValue] = useState("");
  const { mutate } = useCustomMutate(updateTerms, []);
  const { responseData } = useTerms();

  useEffect(() => {
    if (responseData) {
      setValue(responseData);
      //   console.log(responseData);
    }
  }, [responseData]);
  const update = () => {
    // console.log("value", value);
    mutate({
      content: value,
    });
  };
  return (
    <div>
      <Stack
        direction={"column"}
        spacing={2}
        // className="popup-footer-border"
      >
        <ReactQuill
          value={value}
          onChange={setValue}
          modules={Editor.modules}
          formats={Editor.formats}
        />
        <Button
          // type="submit"
          variant="contained"
          className={"capitalize h-42 primary-btn-class mt-76"}
          // disabled={!inspector}
          sx={{ boxShadow: "none", width: "128px" }}
          onClick={update}
        >
          Update
        </Button>
      </Stack>
    </div>
  );
}

export default Editor;

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [
      "link",
      //  "image", "video"
    ],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  // "image",
  // "video",
];
