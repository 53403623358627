import { useQuery } from "@tanstack/react-query";
import { getCalendar } from "../sevices/calendarApi";

export function useCalendar(dates) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["calendar"],
    queryFn: () => getCalendar(dates),
  });

  const responseData = data?.data?.data?.response_data;

  return { isLoading, error, responseData, isError, refetch };
}
