import React, { useEffect, useState } from "react";
import { useCustomParams } from "../../../../../hooks/useCustomParams";
import { useParams } from "react-router-dom";
import { useTickets } from "../../../../../reactQuery/job";
import Loader from "../../../../../components/Loader";
import CustomTable from "../../../../../components/Table/CustomTable";
import { columns } from "./tableColumns";

function Table() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(4);
  const [search] = useCustomParams("search");
  const param = useParams();

  const {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    reportColours,
  } = useTickets({
    page: page + 1,
    pageSize,
    status: "",
    search: "",
    date: "",
    pagination: false,
    jobType: "unit",
    unitId: param?.unitId ? `&unit_id=${param?.unitId}` : "",
  });

  const completedTickets = responseData?.filter(
    (el) => el?.report_status == "Positive" || el?.report_status == "Negative"
  );

  useEffect(() => {
    refetch();
  }, [param.unitId]);

  const filteredTicketss = search
    ? completedTickets?.filter((item) =>
        item.service_type.toLowerCase().includes(search.toLowerCase())
      )
    : completedTickets;

  return (
    <div>
      {" "}
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <>
          <CustomTable
            initialRows={filteredTicketss}
            columns={columns}
            // defaultSort={"job_id"}
            headerBgColor={"#F5F5F9"}
            rowCount={totalRecords}
            setPaginationModel={(data) => {
              setPage(data.page);
              setPageSize(data.pageSize);
            }}
            page={page}
            pageSize={pageSize}
            subHeight={290}
            hideFooter={true}
            // useMaxHeight={true}
          />
        </>
      )}
    </div>
  );
}

export default Table;
