import React from "react";
import { useMediaQuery } from "react-responsive";

import FilterIcon from "../../../styles/svg/filter-icon.svg";
import SelectDatePicker from "../../../components/SelectDatePicker";
import GlobalSearch from "../../../components/Search";

function Filters() {
  const isMobile = useMediaQuery({ maxWidth: "960px" });
  return (
    <div className="tickets-filter d-flex align-items-center justify-content-space-between">
      <GlobalSearch />
      {/* <div className="d-flex align-items-center">
        {isMobile ? (
          <>
            <div className="d-flex align-items-center">
              <img
                src={FilterIcon}
                style={{ height: "16px", width: "16px" }}
                className="mr-2"
              />
              <div>
                <SelectDatePicker />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex align-items-center mr-4">
              <img
                src={FilterIcon}
                style={{ height: "16px", width: "16px" }}
                className="mr-2"
              />
              <p className="filter-text">Filter By:</p>
            </div>
            <div>
              <SelectDatePicker />
            </div>
          </>
        )}
      </div> */}
    </div>
  );
}

export default Filters;
