import React from "react";
import Filters from "./Filters";
import Table from "./Table";

function InspectorJobs() {
  return (
    <div className="mt-4 ticket-page">
      <Filters />
      <Table />
    </div>
  );
}

export default InspectorJobs;
