import React from "react";

function CheckBoxInstruction({ callback, defaultValue }) {
  return (
    <div>
      <label class="custom-checkbox">
        <input
          type="checkbox"
          id={`board-list-my-task-checkbox-all-board`}
          onClick={(event) => {
            callback?.(event.currentTarget.checked);
          }}
          defaultChecked={defaultValue}
          //   checked={activeBoardList.length == checkedList.length}
        />
        <span class="checkmark"></span>
      </label>
    </div>
  );
}

export default CheckBoxInstruction;
