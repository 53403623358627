import React from "react";
import Editor from "./Editor";

function TermPage() {
  return (
    <div className="mt-4">
      <Editor />
    </div>
  );
}

export default TermPage;
