import React, { useState } from "react";
import moment from "moment";

import FormDialog from "../../../components/Dialog/FormDialog";
import SchedulingCalendarForm from "./SchedulingCalendarForm";

const DayCellContent = ({ arg }) => {
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };

  const dateStr = moment(arg.date).format("YYYY-MM-DD"); // Format date to YYYY-MM-DD
  const eventsOnThisDate = arg.view
    .getCurrentData()
    .options.events.filter((event) => event.start === dateStr);

  // console.log("Events on this date:", eventsOnThisDate);
  // console.log(
  //   "arg",
  //   dateStr,
  //   arg.view
  //     .getCurrentData()
  //     .options.events.filter((event) => event.start === dateStr),
  //   arg.dayNumberText
  // );
  const updateDate = () => {
    const dateToCheck = moment(dateStr);

    // Check if the date is before today
    const isBeforeToday = dateToCheck.isBefore(moment(), "day");
    if (!isBeforeToday) handleClick();
  };

  console.log(eventsOnThisDate[0]);

  return (
    <>
      {anchorEl && (
        <FormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Scheduling Calendar"}
          btnTitle={"Confirm"}
        >
          <SchedulingCalendarForm
            onClose={handleClose}
            data={{
              job_limit: eventsOnThisDate[0]?.job_limit,
              scheduled_date: new Date(
                moment(eventsOnThisDate[0]?.scheduled_date)
              ),
              is_blocked: eventsOnThisDate[0]?.blocked_date,
            }}
          />
        </FormDialog>
      )}
      <div
        onClick={updateDate}
        className={`${
          eventsOnThisDate[0]?.blocked_date ? "blocked-date " : ""
        } date-number `}
      >
        {arg.dayNumberText}
      </div>
    </>
  );
};

export default DayCellContent;
