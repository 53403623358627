import { useQuery } from "@tanstack/react-query";
import { getJobs, getReportOptions } from "../sevices/jobApi";

export function useJobs(filters) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["jobs"],
    queryFn: () => getJobs(filters),
  });

  const reportColours = data?.data?.data?.report_status_colour
    ? JSON.parse(data?.data?.data?.report_status_colour)
    : [];

  const responseData = data?.data?.data?.response_data?.map((el, idx) => {
    const status_colour = reportColours.find(
      (element) => element.status == el.report_status
    )?.color;
    return {
      ...el,
      id: idx + 1,
      status_colour,
    };
  });
  const totalRecords = data?.data?.data?.total_records || 0;

  return {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    // reportColours,
  };
}

export function useTickets(filters) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: [`tickets-${filters.jobType}`],
    queryFn: () => getJobs(filters),
  });

  const reportColours = data?.data?.data?.report_status_colour
    ? JSON.parse(data?.data?.data?.report_status_colour)
    : [];

  const responseData = data?.data?.data?.response_data?.map((el, idx) => {
    const status_colour = reportColours.find(
      (element) => element.status == el.report_status
    )?.color;
    return {
      ...el,
      id: idx + 1,
      status_colour,
    };
  });
  const totalRecords = data?.data?.data?.total_records || 0;

  return {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    // reportColours,
  };
}

export function useReportOptions() {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["report-options"],
    queryFn: getReportOptions,
  });

  const responseData = data?.data?.data?.response_data;

  return { isLoading, error, responseData, isError, refetch };
}
