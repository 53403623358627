import React, { useState } from "react";
import greyedIcon from "../../../styles/svg/greyed-report-icon.svg";
import FormDialog from "../../../components/Dialog/FormDialog";
import ReportUploadForm from "./ReportUploadForm";

function ReportUpload({ params }) {
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };
  return (
    <>
      {anchorEl && (
        <FormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Edit Report"}
          btnTitle={"Confirm"}
        >
          <ReportUploadForm params={params} onClose={handleClose} />
        </FormDialog>
      )}
      <img src={greyedIcon} className="cursor-pointer" onClick={handleClick} />
    </>
  );
}

export default ReportUpload;
