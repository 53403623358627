import React from "react";
import Heading from "./Heading";

function index() {
  return (
    <div>
      <Heading />
    </div>
  );
}

export default index;
