import React, { useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import { login } from "../../sevices/apiAuth";
// import GraphicElements from "../../styles/svg/graphic_elements.svg";
import Logo from "../../../styles/svg/Logo.svg";
import { useDispatch } from "react-redux";
import { forgotUser, loginUser, resetPassword } from "../../../sevices/authApi";
// import { user } from "../../redux/usersSlice";
import image from "../../../styles/img/auth-image.png";
import LeftArrow from "../../../styles/svg/left-arrow.svg";
import "../Auth.scss";

import { checkToken } from "../../../sevices/authApi";
import Loader from "../../../components/Loader";
import toast from "react-hot-toast";

function ResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [apiError, setApiError] = React.useState("");
  const [apiSuccess, setApiSuccess] = React.useState("");
  const [isValidLink, setValidLink] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("key");

  const { register, handleSubmit, reset, formState, watch } = useForm({
    defaultValues: {},
  });
  const { errors } = formState;
  const password = watch("new_password");

  useEffect(() => {
    checkToken(token)
      .then((res) => {
        // console.log(res.data.data.responseData[0]);
        let data = res;
        console.log(data);
        setValidLink(data.data.data.is_valid);
      })
      .catch((err) => {
        console.log(err);
        if (err.code == "ERR_NETWORK") {
          setApiError("An unexpected error occurred. Please try again later");
        } else setApiError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function onError(errors) {
    console.log(errors);
  }

  const submit = (data) => {
    resetPassword({ ...data, reset_url: token })
      .then((res) => {
        // console.log(res.data.data.responseData[0]);
        let data = res.data.data;
        console.log(data);
        toast.success(res.data.message);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        if (err.code == "ERR_NETWORK") {
          setApiError("An unexpected error occurred. Please try again later");
        } else setApiError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Box
        component="main"
        // sx={{ backgroundImage: `url(${BgSvg})` }}
        sx={{
          height: "100vh",
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          // backgroundImage: `url(${GraphicElements})`,
          // backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // backgroundPosition: "center",
        }}
        disableGutters={true}
      >
        <Box
          sx={{
            // display: "flex",
            // flexDirection: "column",
            // alignItems: "center",
            width: "400px",
            // justifyContent: "center",
            // height: "100%",
            padding: "0px 40px",
          }}
        >
          {loading ? (
            <div className="loader-center">
              <Loader color={"black"} />
            </div>
          ) : (
            <>
              <img src={Logo} alt="not found" />
              {isValidLink ? (
                <Box
                  component="form"
                  onSubmit={handleSubmit(submit, onError)}
                  noValidate
                  sx={{
                    marginTop: "60px",
                    // padding: "0px 40px",
                    borderRadius: "16px",
                    // boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
                    background: "#ffffff",
                  }}
                >
                  <Typography
                    className="page-header-text"
                    component="h1"
                    variant="h5"
                  >
                    Reset Password
                  </Typography>
                  <Typography className="info-text">
                    Please enter the new Password.
                  </Typography>
                  {apiError && <Alert severity="error">{apiError}</Alert>}
                  {apiSuccess && <Alert severity="success">{apiSuccess}</Alert>}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="new_password"
                    label="New Password"
                    type={"text"}
                    id="new_password"
                    error={errors?.new_password ? true : false}
                    helperText={
                      errors?.new_password && errors.new_password.message
                    }
                    autoComplete="current-new_password"
                    {...register("new_password", {
                      required: "new_password is required",
                    })}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirm_password"
                    label="Confirm Password"
                    type={"text"}
                    id="confirm_password"
                    error={errors?.confirm_password ? true : false}
                    helperText={
                      errors?.confirm_password &&
                      errors.confirm_password.message
                    }
                    autoComplete="current-confirm_password"
                    {...register("confirm_password", {
                      required: "Confirm Password is required",
                      validate: (value) =>
                        value === password || "Passwords do not match",
                    })}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="btn-blue"
                    sx={{
                      mt: "14px",
                      padding: "13px 16px",
                      bgcolor: "#ED7047",
                      boxShadow: "none",
                    }}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                  <div className="sign-up-bottom-container">
                    <p className="sign-up-bottom align-items-center d-flex">
                      <img src={LeftArrow} />
                      <span onClick={() => navigate("/")}>Back to Login</span>
                    </p>
                  </div>
                </Box>
              ) : (
                <div
                  style={{
                    marginTop: "60px",
                    width: "400px",
                  }}
                >
                  <Alert severity="error">Your Link has Expired</Alert>
                  <div className="sign-up-bottom-container">
                    <p className="sign-up-bottom align-items-center d-flex">
                      <img src={LeftArrow} />
                      <span onClick={() => navigate("/forgot-password")}>
                        Back to Forgot Password
                      </span>
                    </p>
                  </div>
                </div>
              )}{" "}
            </>
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            position: "relative",
            display: { xs: "none", md: "block" },
          }}
          className="right-box"
        >
          <img
            src={image}
            alt="Right side background"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <Box
            sx={{
              position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
              // color: "white",
              // textAlign: "center",
            }}
            className="text-overlay"
          >
            <Typography variant="h3" component="div">
              CompliNYC
            </Typography>
            {/* <Typography variant="body1" component="div" mt={2}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography> */}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ResetPassword;
