import React, { useEffect, useState } from "react";
import {
  DateRangePicker,
  DateRange as DateRangeMobile,
} from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// import { useDispatch } from "react-redux";

import { Button } from "@mui/material";

import moment from "moment";
import { useCustomParams } from "../hooks/useCustomParams";
import { useMediaQuery } from "react-responsive";

function DateRange({ onClose }) {
  const isMobile = useMediaQuery({ maxWidth: "611px" });
  const [Olddate, updateSearchParams, removeSearchParam] =
    useCustomParams("date");
  const date = JSON.parse(Olddate);
  // console.log(date);
  const [state, setState] = useState([
    date
      ? date
      : {
          startDate: date ? new Date(date.startDate) : new Date(),
          endDate: date ? new Date(date.endDate) : new Date(),
          key: "selection",
        },
  ]);
  const [selectDate, setSelectDate] = useState("");

  const handleChange = (item, event) => {
    setState([item.selection]);
    console.log(item.selection);

    const date1 = moment(item.selection.startDate);
    const date2 = moment(item.selection.endDate);

    // Check if they are the same day
    const isSameDay =
      date1.isSame(date2, "year") &&
      date1.isSame(date2, "month") &&
      date1.isSame(date2, "day") &&
      date1.isSame(date2, "hour") &&
      date1.isSame(date2, "minute") &&
      date1.isSame(date2, "second");

    if (isSameDay) {
      console.log("same Date");
      setSelectDate(true);
      if (selectDate) {
        setData(item.selection);
      }
    } else {
      setData(item.selection);
    }
    // if (selectDate) {
    // }
    // setData(item.selection);
  };

  const setData = (dateState) => {
    console.log("state[0]", dateState);
    setSelectDate(false);
    // const date = {
    //   ...dateState,
    //   endDate: new Date(
    //     moment(dateState.endDate).set({
    //       hour: 23,
    //       minute: 59,
    //       second: 59,
    //       millisecond: 0,
    //     })
    //   ),
    // };
    const utcDateTime = {
      ...dateState,
      startDate: moment(dateState.startDate).format("YYYY-MM-DD"),
      endDate: moment(dateState.endDate).format("YYYY-MM-DD"),
    };
    updateSearchParams("date", JSON.stringify(utcDateTime));
    onClose();
  };

  return (
    <div className="d-grid">
      {isMobile ? (
        <DateRangeMobile
          onChange={handleChange}
          editableDateInputs={true}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={state}
          direction="horizontal"
        />
      ) : (
        <DateRangePicker
          onChange={handleChange}
          editableDateInputs={true}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={state}
          direction="horizontal"
          className="react-date-range-popup"
        />
      )}

      <div className="d-flex">
        <Button
          onClick={() => {
            onClose();
            removeSearchParam("date");
          }}
          className="reset-date-picker"
        >
          Reset
        </Button>
        {/* <Button
          onClick={setData}
          className="submit-date-picker"
          variant="contained"
        >
          Submit
        </Button> */}
      </div>
    </div>
  );
}

export default DateRange;
