import { Navigate, Outlet } from "react-router-dom";

const Protected = () => {
  const auth = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("userData"));

  if (!auth) {
    return <Outlet />;
  } else {
    if (user?.role == "admin") {
      return <Navigate to="/calendar" />;
    } else if (user?.role == "client") {
      return <Navigate to={`/client/${user.user_id}`} />;
    } else if (user?.role == "inspector") {
      return <Navigate to={`/inspector-role`} />;
    }
  }
  // return auth ? <Navigate to={`/client/${user.user_id}`} /> : <Outlet />;
};

export default Protected;
