import React from "react";
import { Box, Grid, Stack } from "@mui/material";

import CalendarIcon from "../../../styles/svg/calendar-icon.svg";
import NonAccessIcon from "../../../styles/svg/not-access.svg";
import ReportsIcon from "../../../styles/svg/uploaded-records-icon.svg";
import { useMediaQuery } from "react-responsive";

function Heading() {
  const isMobile = useMediaQuery({ maxWidth: "900px" });
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        className="d-flex align-items-center justify-content-space-between"
      >
        <Grid item xs={12} md={4}>
          <p className="page-heading-name">Calendar</p>
        </Grid>
        <Grid
          className={`d-flex ${isMobile ? "" : "justify-content-end"}`}
          item
          xs={12}
          md={8}
        >
          <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 2 }}>
            <p className="icon-defination d-flex align-items-center">
              <img src={CalendarIcon} className="mr-1" />
              Booked
            </p>
            <p className="icon-defination d-flex align-items-center">
              <img src={ReportsIcon} className="mr-1" />
              Reports Uploaded
            </p>
            <p className="icon-defination d-flex align-items-center">
              <img src={NonAccessIcon} className="mr-1" />
              Access Issues
            </p>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Heading;
