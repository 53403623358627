import React from "react";
import { useMediaQuery } from "react-responsive";

import FilterIcon from "../../../../../styles/svg/filter-icon.svg";
import SelectDatePicker from "../../../../../components/SelectDatePicker";
import CustomSelectDropdown from "../../../../../components/CustomSelectDropDown";
import PrimaryButton from "../../../../../components/Button/Button";
import { useCustomParams } from "../../../../../hooks/useCustomParams";
import { useRemoveAllFilters } from "../../../../../hooks/useRemoveAllFilters";

function Filters() {
  const isMobile = useMediaQuery({ maxWidth: "690px" });
  const [status, updateStatus] = useCustomParams("status");
  const [date, updateDate] = useCustomParams("date");
  const [removeAll] = useRemoveAllFilters();

  const buttons = [
    { label: "Pending Tickets", value: "Pending" },
    { label: "Completed Tickets", value: "Completed" },
  ];

  const removeFilter = () => {
    removeAll(["status"]);
  };
  return (
    <div className="tickets-filter d-flex align-items-center justify-content-space-between">
      <div>
        {isMobile ? (
          <CustomSelectDropdown
            options={buttons}
            label="Filter by Days"
            onSelectChange={(value) => updateDate("status", value)}
            defaultValue={status}
          />
        ) : (
          buttons.map((el, idx) => {
            return (
              <PrimaryButton
                variant="contained"
                key={idx}
                className={`capitalize ${
                  el.value == status ? "btn-bg-grey" : "btn-bg-light-grey"
                } `}
                onClick={() => updateDate("status", el.value)}
              >
                {el.label}
              </PrimaryButton>
            );
          })
        )}
      </div>
      <div className="d-flex align-items-center">
        <SelectDatePicker />
      </div>
    </div>
  );
}

export default Filters;
