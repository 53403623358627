import React from "react";
import { Button } from "@mui/material";

function PrimaryButton({ variant, className, children, onClick, ...rest }) {
  return (
    <Button
      sx={{ height: "36px", boxShadow: "none" }}
      variant={variant}
      className={className}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
