import { useQuery } from "@tanstack/react-query";
import { getInspector } from "../sevices/inspectorApi";

export function useInspector(filter) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["inspector"],
    queryFn: () => getInspector(filter),
  });

  const responseData = data?.data?.data?.response_data?.map((el, idx) => {
    return {
      ...el,
      id: idx + 1,
    };
  });
  const totalRecords = data?.data?.data?.total_records || 0;

  return { isLoading, error, responseData, isError, refetch, totalRecords };
}
