import moment from "moment";
import Api from "./axios";

export const getCalendar = async (data) => {
  return await Api.get(
    `/v1/job/calendar?start_date=${moment(data.start_date).format(
      "YYYY-MM-DD"
    )}&end_date=${moment(data.end_date).format("YYYY-MM-DD")}`
  );
};

export const updateCalendar = async (data) => {
  return await Api.post(
    `/v1/date-override/custom-job-limit?scheduled_date=${data.scheduled_date}&job_limit=${data.job_limit}&is_blocked=${data.is_blocked}`
  );
};
