import React from "react";
import { Box, Grid, Stack } from "@mui/material";

function Heading({ detail }) {
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid item xs={4} md={4}>
            <p className="page-heading-name">{`${detail?.[0]?.unit_size} - ${detail?.[0]?.unit_number}`}</p>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Heading;
