import React, { useState } from "react";
import editIcon from "../../../styles/svg/edit-icon.svg";
import LongFormDialog from "../../../components/Dialog/LongFormDialog";
import EditTicket from "./EditTicket";

function EditIcon({ params }) {
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };
  return (
    <>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Edit Ticket"}
          btnTitle={"Confirm"}
        >
          <EditTicket onClose={handleClose} data={params.row} />
        </LongFormDialog>
      )}
      <img
        src={editIcon}
        className="mr-1 cursor-pointer "
        onClick={handleClick}
      />
    </>
  );
}

export default EditIcon;
