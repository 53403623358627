import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Stack,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import useCustomForm from "../../../../../hooks/useForm";
import AddIcon from "../../../../../styles/svg/add-building.svg";
import useCustomMutate from "../../../../../hooks/useMutate";
import {
  createBuilding,
  updateBuilding,
} from "../../../../../sevices/buildingApi";
import {
  deFormatPhoneNumber,
  formatPhoneNumber,
} from "../../../../../utils/helpers";
import DeleteIcon from "../../../../../styles/svg/delete-icon.svg";

function BuildingForm({ initialValues, onClose, isEdit }) {
  const [loading, setLoading] = useState(false);
  const [removedApartments, setRemovedApartments] = useState([]);
  const { mutate } = useCustomMutate(
    isEdit ? updateBuilding : createBuilding,
    ["building"],
    onClose,
    () => {
      setLoading(false);
    }
  );
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    handleReset,
    setValue,
    watch,
    setError,
    clearErrors,
    isValid,
    useFieldArray,
    Controller,
    control,
  } = useCustomForm(initialValues);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "apartments",
  });

  const formFields = [
    {
      name: "client",
      label: "Client",
      type: "text",
      disabled: true,
      placeholder: "Select date",
      size: 6,
    },
    {
      name: "address",
      label: "Building Address",
      type: "text",
      placeholder: "Enter Address",
      size: 6,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Enter City",
      size: 4,
    },
    {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "Enter State",
      size: 4,
    },
    {
      name: "zip_code",
      label: "Zip Code",
      type: "text",
      placeholder: "Enter Zip",
      size: 4,
    },
    {
      name: "mdr",
      label: "MDR",
      type: "text",
      placeholder: "Enter MDR",
      size: 4,
    },
    {
      name: "block",
      label: "Block",
      type: "text",
      placeholder: "Enter Block",
      size: 4,
    },
    {
      name: "lot",
      label: "LOT",
      type: "text",
      placeholder: "Enter LOT",
      size: 4,
    },

    {
      name: "borough",
      label: "Borough",
      type: "text",
      placeholder: "Enter borough",
      size: 4,
    },
    {
      name: "superintendent_name",
      label: "Superintendent Name",
      placeholder: "Enter Superintendent name",
      type: "text",
      size: 4,
    },
    {
      name: "superintendent_number",
      label: "Superintendent Phone",
      placeholder: "Enter Phone",
      type: "number",
      size: 4,
    },
    // {
    //   name: "superintendent_number",
    //   label: "Superintendent Apt",
    //   placeholder: "Enter Apt",
    //   type: "text",
    //   size: 4,
    // },
  ];

  const submit = (data) => {
    setLoading(true);
    const buildingData = {
      ...data,
      superintendent_number: deFormatPhoneNumber(data.superintendent_number),
    };
    delete buildingData.client;
    const buiding = isEdit
      ? {
          data: {
            ...buildingData,
            apartments: [...buildingData.apartments, ...removedApartments],
          },
          id: initialValues.building_id,
        }
      : buildingData;
    mutate(buiding);
  };
  console.log(errors);

  const handleRemove = (item, index) => {
    // Add the removed apartment to the removedApartments state with deleted: true
    if (item?.unit_id)
      setRemovedApartments((prev) => [...prev, { ...item, deleted: true }]);

    // Remove the apartment from the field array
    remove(index);
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack>
        <Grid container spacing={4}>
          {formFields.map((field, index) => (
            <Grid item xs={12} md={field.size} key={index}>
              {field.type === "number" ? (
                <TextField
                  label={field.label}
                  fullWidth
                  {...register(field.name, {
                    required: `${field.label} is required`,
                  })}
                  onChange={(e) => {
                    setValue(
                      "superintendent_number",
                      formatPhoneNumber(e.target.value)
                    );
                  }}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              ) : field.type === "textarea" ? (
                <TextField
                  label={field.label}
                  multiline
                  rows={3}
                  fullWidth
                  {...register(field.name)}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  InputLabelProps={{ shrink: true }}
                />
              ) : (
                <TextField
                  label={field.label}
                  fullWidth
                  {...register(field.name, {
                    required: `${field.label} is required`,
                  })}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              )}
            </Grid>
          ))}
        </Grid>
        <div className="p-24-0 d-flex">
          <p className="schedule-appointment-heading">Apartments</p>
          <div
            className="ml-2 cursor-pointer"
            onClick={() =>
              append({ unit_number: "", unit_size: "", is_exempt: false })
            }
          >
            <img src={AddIcon} />
          </div>
        </div>
        {fields.map((item, index) => (
          <Grid container spacing={4} key={index}>
            <Grid item xs={12} md={5} key={index}>
              <Controller
                name={`apartments.${index}.unit_number`}
                control={control}
                render={({ field }) => (
                  <>
                    {console.log("field.name", field.name)}
                    <TextField
                      {...field}
                      label={"Apt#"}
                      placeholder={"Enter Here"}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      {...register(field.name, {
                        required: `Apt is required`,
                      })}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors?.apartments?.[index]?.["unit_number"]}
                      helperText={
                        errors?.apartments?.[index]?.["unit_number"]?.message
                      }
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={5} key={index}>
              <Controller
                name={`apartments.${index}.unit_size`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={"# of Rooms"}
                    placeholder={"Enter Here"}
                    variant="outlined"
                    fullWidth
                    {...register(field.name, {
                      required: `# of Rooms is required`,
                    })}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors?.apartments?.[index]?.["unit_size"]}
                    helperText={
                      errors?.apartments?.[index]?.["unit_size"]?.message
                    }
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              key={index}
              className="d-flex justify-content-space-between"
            >
              <div className="exempt-field">
                <p className="status-text ">Is Exempt</p>
                <Controller
                  name={`apartments.${index}.is_exempt`}
                  control={control}
                  render={({ field }) => (
                    <label class="custom-checkbox-large isexempt-checkbox">
                      {console.log("field", field)}
                      <input
                        type="checkbox"
                        {...field}
                        defaultChecked={field?.value ? true : false}
                      />
                      <span class="checkmark-large"></span>
                    </label>
                  )}
                />
              </div>
              <div className="d-flex align-items-center cursor-pointer">
                <img
                  src={DeleteIcon}
                  onClick={() => handleRemove(item, index)}
                />
              </div>
            </Grid>
          </Grid>
        ))}
        <Stack
          sx={{
            alignSelf: "end",
          }}
          direction={"row"}
          spacing={2}
          className="popup-footer-border"
        >
          <Button
            variant="outlined"
            className="secondary-btn-class"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            className={loading ? "capitalize h-42" : "primary-btn-class"}
            disabled={loading}
            sx={{ boxShadow: "none" }}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
      {/* </Grid> */}
    </form>
  );
}

export default BuildingForm;
