import moment from "moment";

export function formatCurrency(value) {
  return new Intl.NumberFormat("en", {
    style: "currency",
    currency: "EUR",
  }).format(value);
}

export function formatDate(dateStr) {
  return new Intl.DateTimeFormat("en", {
    day: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(dateStr));
}

export function calcMinutesLeft(dateStr) {
  const d1 = new Date().getTime();
  const d2 = new Date(dateStr).getTime();
  return Math.round((d2 - d1) / 60000);
}

export function objectToFormData(obj) {
  const formData = new FormData();

  // Iterate through the object's keys
  for (let key in obj) {
    // Check if the key is a property of the object (not inherited)
    if (obj.hasOwnProperty(key)) {
      formData.append(key, obj[key]);
    }
  }

  return formData;
}

export function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  } else {
    return text;
  }
}

export function createAvtarName(username) {
  return username
    .split(" ")
    .map((el, i) => (i < 2 ? el[0] : ""))
    .join("");
}

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const differenceInDays = (date) => {
  const date1 = moment(date);
  const date2 = moment();

  // Calculate the difference in days
  return date2.diff(date1, "days");
};

export const differenceInDaysOrHours = (date) => {
  const date1 = moment.utc(date).local();
  const date2 = moment();
  if (date == null) {
    return `not seen yet`;
  }
  const diffInMinutes = date2.diff(date1, "minutes");
  if (diffInMinutes < 1) {
    return `just now`;
  }
  if (diffInMinutes < 60) {
    return `${diffInMinutes} minutes ago`;
  }
  // Calculate the difference in hours
  const diffInHours = date2.diff(date1, "hours");
  if (diffInHours < 24) {
    return `${diffInHours} hours ago`;
  } else {
    // Calculate the difference in days
    const diffInDays = date2.diff(date1, "days");
    return `${diffInDays} days ago`;
  }
};

export const hasImage = (imagePath) => {
  if (!imagePath) {
    return false;
  }
  // Define a regular expression to match common image file extensions
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg|tiff|ico)$/i;

  // Test if the imagePath ends with one of the image file extensions
  return imageExtensions.test(imagePath);
};

export const compareDates = (dateTime1, dateTime2) => {
  // Extract the date part (YYYY-MM-DD) from the datetime strings
  const date1 = dateTime1.split(" ")[0];
  const date2 = dateTime2.split(" ")[0];

  // Compare the dates and return true if they are equal, otherwise false
  return date1 === date2;
};

export const getInitials = (first_name, last_name) => {
  // console.log("first_name",first_name)
  // const { first_name, last_name } = data;

  // Check if first_name and last_name are provided and not empty
  if (first_name && last_name) {
    const firstInitial = first_name.charAt(0).toUpperCase();
    const lastInitial = last_name.charAt(0).toUpperCase();
    // console.log("firstInitial",firstInitial)
    return `${firstInitial}${lastInitial}`;
  } else {
    return "";
  }
};

export const relativeTime = (date) => {
  const localDate = moment.utc(date).local();
  return localDate.fromNow();
};

export function getCalendarBounds() {
  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const startDate = moment([currentYear, currentMonth - 1, 22])
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  const endDate = moment([currentYear, currentMonth + 1, 9])
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  return {
    start_date: startDate,
    end_date: endDate,
  };
}

export function convertInUTC(startDate, endDate) {
  const endDateTimeZone = (dateTime) => {
    const endDateLocal = moment(dateTime).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    });
    return endDateLocal.utc();
  };

  console.log("dates", startDate, endDateTimeZone(endDate));
  return { startDate, endDate };
}

export const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const deFormatPhoneNumber = (formattedValue) => {
  if (!formattedValue) return formattedValue;
  return formattedValue.replace(/[^\d]/g, "");
};
