import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const ChangeStatus = ({ options, label, onSelectChange, defaultValue }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    onSelectChange(value);
  };

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="assign-inspector-drop-down">
      <FormControl fullWidth className="filter-select">
        <Select
          sx={{
            height: "35px",
            minWidth: "192px",
            alignItems: "center",
            display: "flex",
          }}
          value={selectedValue}
          onChange={handleChange}
          //   defaultValue={selectedValue || ""}
          displayEmpty
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value} className="text-status">
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ChangeStatus;
