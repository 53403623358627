import moment from "moment";
import Chat from "../chat";
import ShowDetails from "./ShowDetails";
import ChangeStatus from "./ChangeStatus";

export const columns = [
  {
    field: "scheduled_date",
    headerName: "Date",
    renderCell: (params) => {
      return <p>{moment(params.value).format("MM/DD/YYYY")}</p>;
    },
    minWidth: 130,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  // {
  //   field: "client_name",
  //   headerName: "Client",
  //   renderCell: (params) => {
  //     return <p className="underline">{params.value}</p>;
  //   },
  //   minWidth: 100,
  //   flex: 1,
  //   // editable: true,
  //   sortable: false,
  // },
  {
    field: "building_address",
    headerName: "Building",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 110,
    flex: 1.5,
    // editable: true,
  },
  {
    field: "apartment",
    headerName: "Apt#",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 80,
    flex: 0.5,
    // editable: true,
    sortable: false,
  },
  {
    field: "unit_size",
    headerName: "Unit Size",
    renderCell: (params) => {
      return <p>{params.value}</p>;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
  {
    field: "service_type",
    headerName: "Service Type",
    renderCell: (params) => {
      return (
        <p
          className={`${params.value ? "status-chip" : ""}`}
          style={{ backgroundColor: params.row.service_icon || "#11b50c" }}
        >
          {params.value}
        </p>
      );
    },
    minWidth: 130,
    flex: 1.2,
    // editable: true,
    sortable: false,
  },
  {
    field: "unit_status",
    headerName: "Unit Status",
    renderCell: (params) => {
      return (
        <p className={`${params.value ? "status-chip" : ""} bg-blue-status`}>
          {params.value}
        </p>
      );
    },
    minWidth: 100,
    flex: 0.9,
    // editable: true,
    sortable: false,
  },

  {
    field: "report_status",
    headerName: "Status",
    renderCell: (params) => {
      return <ChangeStatus params={params} />;
    },
    minWidth: 120,
    flex: 0.9,
    // editable: true,
    sortable: false,
  },

  {
    field: "action",
    headerName: "Action",
    renderCell: (params) => {
      return (
        <div className="d-flex">
          {params.row.report_status == "Pending" ? (
            <Chat params={params} />
          ) : (
            <div style={{ width: "24px" }} />
          )}
          <ShowDetails params={params} />
        </div>
      );
    },
    minWidth: 100,
    flex: 0.9,
    // editable: true,
    sortable: false,
  },
];
