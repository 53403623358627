import React from "react";
import Heading from "./components/Heading";
import TermPage from "./components/TermPage";
import "./terms.scss";

function index() {
  return (
    <div>
      <Heading />
      <TermPage />
    </div>
  );
}

export default index;
