import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const NumberInput = ({ setNumber, defaultValue, is_blocked }) => {
  const [value, setValue] = useState(defaultValue);

  const handleIncrement = () => {
    setNumber("job_limit", value + 1);
    setValue((prev) => prev + 1);
  };

  const handleDecrement = () => {
    setNumber("job_limit", value > 0 ? value - 1 : 0);
    setValue((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setValue(isNaN(newValue) ? 0 : newValue);
    setNumber("job_limit", isNaN(newValue) ? 0 : newValue);
  };

  return (
    <Box display="flex" alignItems="center">
      <Button
        variant="contained"
        onClick={handleDecrement}
        style={{
          width: "42px",
          height: "42px",
          padding: 0,
          maxWidth: "42px",
          minWidth: "42px",
          backgroundColor: " #E5E7EC",
          border: "1px solid #E5E7EC",
        }}
        sx={{ boxShadow: "none" }}
        disabled={is_blocked}
      >
        <Remove sx={{ color: "black" }} />
      </Button>
      <TextField
        value={value}
        onChange={handleChange}
        inputProps={{ style: { textAlign: "center" } }}
        sx={{
          width: "102px",
          margin: "0 10px",
          padding: "0",
          height: "42px",
        }}
        variant="outlined"
        className="text-field-calendar"
        disabled={is_blocked}
      />
      <Button
        variant="contained"
        onClick={handleIncrement}
        style={{
          width: "42px",
          height: "42px",
          padding: 0,
          maxWidth: "42px",
          minWidth: "42px",
          backgroundColor: " #E5E7EC",
          border: "1px solid #E5E7EC",
        }}
        sx={{ boxShadow: "none" }}
        disabled={is_blocked}
      >
        <Add sx={{ color: "black" }} />
      </Button>
    </Box>
  );
};

export default NumberInput;
