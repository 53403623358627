import React from "react";
import { useMediaQuery } from "react-responsive";
import { Stack } from "@mui/material";

import FilterIcon from "../../../../styles/svg/filter-icon.svg";
import PrimaryButton from "../../../../components/Button/Button";
import RadioFilter from "../../../../components/Radio/RadioFilter";
import { useCustomParams } from "../../../../hooks/useCustomParams";
import { useRemoveAllFilters } from "../../../../hooks/useRemoveAllFilters";
import CustomSelectDropdown from "../../../../components/CustomSelectDropDown";

function Filters() {
  const [status] = useCustomParams("status");
  const [date, updateDate] = useCustomParams("date");
  const [removeAll] = useRemoveAllFilters();
  const isMobile = useMediaQuery({ maxWidth: "690px" });
  const buttons = [
    { label: "Today", value: "today" },
    { label: "This Week", value: "thisWeek" },
    { label: "This Month", value: "thisMonth" },
  ];

  const removeFilter = () => {
    removeAll(["status"]);
  };
  return (
    <div className="tickets-filter d-flex align-items-center justify-content-space-between">
      <div>
        {isMobile ? (
          <CustomSelectDropdown
            options={buttons}
            label="Filter by Days"
            onSelectChange={(value) => updateDate("date", value)}
            defaultValue={date}
          />
        ) : (
          buttons.map((el, idx) => {
            return (
              <PrimaryButton
                variant="contained"
                key={idx}
                className={`capitalize ${
                  el.value == date ? "btn-bg-grey" : "btn-bg-light-grey"
                } `}
                onClick={() => updateDate("date", el.value)}
              >
                {el.label}
              </PrimaryButton>
            );
          })
        )}
      </div>
      <div className="d-flex align-items-center">
        <div>
          {status ? (
            <p className="clear-filter" onClick={removeFilter}>
              Clear Filter
            </p>
          ) : (
            ""
          )}
        </div>
        {isMobile ? (
          <>
            <div className="d-flex align-items-center">
              <img
                src={FilterIcon}
                style={{ height: "16px", width: "16px" }}
                className="mr-2"
              />
            </div>
            <div className="d-flex align-items-center">
              <Stack direction="row" spacing={2}>
                <RadioFilter title={"Pending"} value={"Pending"} />
              </Stack>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex align-items-center mr-3">
              <img
                src={FilterIcon}
                style={{ height: "16px", width: "16px" }}
                className="mr-2"
              />
              <p className="filter-text">Filter By:</p>
            </div>
            <div className="d-flex align-items-center">
              <Stack direction="row" spacing={2}>
                <RadioFilter title={"Pending"} value={"Pending"} />
              </Stack>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Filters;
