import React from "react";
import viewIcon from "../../../../../styles/svg/view-docs.svg";
import { downloadReport } from "../../../../../sevices/jobApi";

function ViewDocument({ params }) {
  const downloadFile = () => {
    downloadReport(params.row.job_id)
      .then((res) => {
        window.open(
          `${process.env.REACT_APP_BASE_URL}${res.data.data.file_url}`,
          "_blank"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <img
      src={viewIcon}
      className="mr-1 cursor-pointer"
      onClick={downloadFile}
    />
  );
}

export default ViewDocument;
