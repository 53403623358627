import React from "react";

import "./radio.scss";
import { useSearchParams } from "react-router-dom";

function RadioFilter({ title, value }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedStatus = searchParams.get("status");

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };
  return (
    <div>
      <label
        class="custom-radio"
        onClick={() => updateSearchParams("status", value)}
      >
        <input
          type="radio"
          name={value}
          value={value}
          checked={selectedStatus == value}
        />
        <span class="radio-checkmark"></span>
        <p className="title-radio">{title}</p>
      </label>
    </div>
  );
}

export default RadioFilter;
