import React from "react";
import { Grid } from "@mui/material";
import Service from "./Service";
import { useServices } from "../../../reactQuery/service";
import Loader from "../../../components/Loader";

function List() {
  const { isLoading, responseData: services } = useServices();

  return (
    <div className="mt-4">
      <Grid container spacing={4}>
        {services
          ?.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          })
          ?.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={item.service_id}>
              <Service item={item} />
            </Grid>
          ))}
      </Grid>
      {services && services.length > 0 ? (
        ""
      ) : (
        <div className="loader-center">
          {isLoading ? (
            <Loader color={"black"} />
          ) : (
            <p className="orange-color font-family-700">Service Not Found</p>
          )}
        </div>
      )}
    </div>
  );
}

export default List;
