import React, { useState } from "react";
import editIcon from "../../../../../styles/svg/edit-icon.svg";
import { useParams } from "react-router-dom";
import LongFormDialog from "../../../../../components/Dialog/LongFormDialog";
import EditBuilding from "./EditBuilding";
import { useClientDetail } from "../../../../../reactQuery/client";

function Action({ params }) {
  const param = useParams();
  const { responseData, isLoading, isError, error } = useClientDetail(
    param.clientId
  );
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };

  return (
    <div>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Add Building Setup"}
          btnTitle={"Confirm"}
        >
          <EditBuilding
            onClose={handleClose}
            id={param.clientId}
            data={params.row}
            clientName={responseData?.company_name}
          />
        </LongFormDialog>
      )}
      <img src={editIcon} className="cursor-pointer" onClick={handleClick} />
    </div>
  );
}

export default Action;
