import { useQuery } from "@tanstack/react-query";
import { getClient, getClientDetail, getClientId } from "../sevices/clientApi";

export function useClient(filters) {
  const { isLoading, data, error, isError, refetch, isRefetching } = useQuery({
    queryKey: ["clients"],
    queryFn: () => getClient(filters),
  });

  const responseData =
    data?.data?.data?.response_data?.map((el, idx) => {
      return {
        ...el,
        id: idx + 1,
      };
    }) || [];

  const totalRecords = data?.data?.data?.total_records || 0;

  return {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    isRefetching,
  };
}

export function useClientById(id) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["client-id", id],
    queryFn: () => getClientId(id),
  });

  const responseData = data?.data?.data?.response_data;

  return { isLoading, error, responseData, isError, refetch };
}

export function useClientDetail(id) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["client-detail", id],
    queryFn: () => getClientDetail(id),
  });

  const responseData = data?.data?.data?.response_data;

  return { isLoading, error, responseData, isError, refetch };
}
