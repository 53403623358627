import React, { useEffect, useState } from "react";
import { useJobs } from "../../../reactQuery/job";
import { columns } from "./tableColumns";
import CustomTable from "../../../components/Table/CustomTable";
import Loader from "../../../components/Loader";
import { useCustomParams } from "../../../hooks/useCustomParams";

function Table() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [status] = useCustomParams("status");
  const [search] = useCustomParams("search");
  const [date] = useCustomParams("date");
  const encryptDate = JSON.parse(date);
  // encryptDate && convertInUTC(encryptDate.startDate, encryptDate.endDate);
  const key = status
    ? status == "Flexible"
      ? "is_flexible=true"
      : status == "Not Uploaded"
      ? "report_available=false"
      : `status=${status}`
    : "";
  const {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    reportColours,
  } = useJobs({
    page: page + 1,
    pageSize,
    status: key,
    search,
    date: encryptDate,
    pagination: true,
  });

  useEffect(() => {
    refetch();
  }, [page, pageSize, status, search, date]);

  // console.log(columns);

  return (
    <div className="mt-2">
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <CustomTable
          initialRows={responseData}
          columns={columns}
          // defaultSort={"scheduled_date"}
          headerBgColor={"#F5F5F9"}
          rowCount={totalRecords}
          setPaginationModel={(data) => {
            setPage(data.page);
            setPageSize(data.pageSize);
          }}
          page={page}
          pageSize={pageSize}
          subHeight={270}
        />
      )}
    </div>
  );
}

export default Table;
