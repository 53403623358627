import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import useCustomMutate from "../../../hooks/useMutate";
import { updateJobs } from "../../../sevices/jobApi";

function ChangeStatus({ params }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { mutate } = useCustomMutate(updateJobs, ["jobs"], () =>
    setAnchorEl(null)
  );

  const options = [
    { value: "Pending", label: "Pending" },
    { value: "No Access", label: "No Access" },
    { value: "Inspected", label: "Inspected" },
  ];

  const updateStatus = (status) => {
    mutate({
      id: params.row.job_id,
      data: {
        report_status: status,
      },
    });
  };
  return (
    <>
      <p
        className={`${params.value ? "status-chip cursor-pointer" : ""}`}
        style={{ backgroundColor: params.row.status_colour || "#11b50c" }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {params.value}
      </p>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "epics-board-dropdown",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            className="dropdown-menu-options"
            onClick={() => updateStatus(option.value)}
          >
            <p className="dropdown-options"> {option.label}</p>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default ChangeStatus;
