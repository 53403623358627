// useForm.js

import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

const useCustomMutate = (apiFunc, invalidateQue, onClose, onErrorCallback) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: apiFunc,
    onSuccess: (res) => {
      invalidateQue.map((el) => {
        queryClient.invalidateQueries({
          queryKey: [el],
        });
      });
      toast.success(res.data.message);
      onClose?.(res.data);
    },
    onError: (error) => {
      console.error("Error", error);
      onErrorCallback?.();
      toast.error(error.response.data.message);
    },
  });

  return { mutate };
};

export default useCustomMutate;
