import React, { useState } from "react";
import { Grid, Paper, Typography, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ServiceEdit from "../../../styles/svg/service-edit.svg";
import Switch from "./Switch";
import useCustomMutate from "../../../hooks/useMutate";
import { updateService } from "../../../sevices/serviceApi";
import FormDialog from "../../../components/Dialog/FormDialog";
import CreateService from "./CreateService";

function Service({ item }) {
  const { mutate } = useCustomMutate(updateService, ["service"]);
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };

  const callback = (status) => {
    mutate({
      data: { is_active: status, name: item.name },
      id: item.service_id,
    });
  };
  return (
    <>
      {anchorEl && (
        <FormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Edit Service"}
          btnTitle={"Confirm"}
        >
          <CreateService onClose={handleClose} isEdit={true} item={item} />
        </FormDialog>
      )}
      <Paper
        className="service-paper"
        elevation={3}
        style={{ padding: 20, position: "relative" }}
      >
        <Typography className="service-name ">{item.name}</Typography>
        {/* <Switch
        checked={item.enabled}
        color="primary"
        inputProps={{ "aria-label": "controlled" }}
        style={{ position: "absolute", bottom: 16, left: 16 }}
      /> */}
        <IconButton
          color="success"
          style={{ position: "absolute", top: 16, right: 16 }}
          onClick={handleClick}
        >
          <img src={ServiceEdit} />
        </IconButton>
        <div className="mt-4">
          <Switch defaultValue={item.is_active} callback={callback} />
        </div>
      </Paper>
    </>
  );
}

export default Service;
