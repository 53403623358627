import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Typography, Stack } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";

import forwardIcon from "../../../styles/svg/right-arrow-group.svg";
import backwardIcon from "../../../styles/svg/left-arrow-group.svg";
import JobIcon from "../../../styles/svg/calendar-job.svg";
import NotAccessIcon from "../../../styles/svg/not-access.svg";
import RecordsIcon from "../../../styles/svg/uploaded-records-icon.svg";
import "../calendar.scss";
import { useCalendar } from "../../../reactQuery/calendar";
import { getCalendarBounds } from "../../../utils/helpers";
import DayCellContent from "./DayCellContent";
import Loader from "../../../components/Loader";
import { useMediaQuery } from "react-responsive";
import CustomPopover from "../../../components/Popover";
import Status from "./Status";

function Calendar() {
  const calendarRef = useRef(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarDates, setCalendarDates] = useState(getCalendarBounds());
  const { isLoading, error, responseData, isError, refetch } =
    useCalendar(calendarDates);

  useEffect(() => {
    refetch();
    // console.log(
    //   "time zone",
    //   typeof Intl.DateTimeFormat().resolvedOptions().timeZone
    // );
  }, [calendarDates]);

  const handleDatesSet = (dateInfo) => {
    setCalendarDates({
      start_date: moment(dateInfo.start)
        // .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end_date: moment(dateInfo.end)
        // .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    });
  };

  const monthNames = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getCurrentMonthYear = () => {
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    return { month, year };
  };

  const { month, year } = getCurrentMonthYear();

  const handlePrevClick = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setCurrentDate(calendarApi.getDate());
  };

  const handleNextClick = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    setCurrentDate(calendarApi.getDate());
  };

  return (
    <div className={`mt-6 ${isLoading ? "opacity-3" : ""}`}>
      <Stack
        className="justify-content-space-between align-items-center calendar-header p-4"
        direction="row"
      >
        <Stack
          className="justify-content-space-between"
          direction="row"
          spacing={1}
        >
          <Typography className=" month-name">{monthNames[month]}</Typography>
          <Typography className=" month-name">{year}</Typography>
        </Stack>

        <Stack
          className="btn-forword-backword justify-content-space-between"
          direction="row"
          spacing={1}
        >
          <img
            src={backwardIcon}
            alt="backward"
            className="cursor-pointer"
            onClick={handlePrevClick}
          />
          <img
            src={forwardIcon}
            alt="forward"
            className="cursor-pointer"
            onClick={handleNextClick}
          />
        </Stack>
      </Stack>
      <div className="p-relative">
        {isLoading ? (
          <Stack className="calendar-loader">
            <Loader color={"black"} />
          </Stack>
        ) : (
          ""
        )}
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          headerToolbar={false}
          datesSet={handleDatesSet}
          events={
            responseData
              ? responseData.map((el, idx) => {
                  return {
                    ...el,
                    id: idx,
                    start: moment(el.scheduled_date)
                      // .utc(el.scheduled_date)
                      // .local()
                      .format("YYYY-MM-DD"),
                    end: moment(el.scheduled_date)
                      // .utc(el.scheduled_date)
                      .format("YYYY-MM-DD"),
                    title: JSON.stringify(el),
                    display: el.blocked_date ? "background" : "",
                  };
                })
              : []
          }
          fixedWeekCount={false}
          eventContent={(eventInfo) => <Status eventInfo={eventInfo} />}
          dayCellContent={(arg) => <DayCellContent arg={arg} />}
        />
      </div>
    </div>
  );
}

export default Calendar;
