import React from "react";
import Heading from "./components/Heading";
import Calendar from "./components/Calendar";

function CalendarPage() {
  return (
    <>
      <Heading />
      <Calendar />
    </>
  );
}

export default CalendarPage;
