import React from "react";
import { useNavigate } from "react-router-dom";

function RedirectToClient({ params }) {
  const navigate = useNavigate();
  return (
    <p
      className="underline"
      onClick={() => navigate(`/client/${params.row.client_id}`)}
    >
      {params.value}
    </p>
  );
}

export default RedirectToClient;
