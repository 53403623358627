import React, { useState } from "react";
import CustomMenu from "../../../components/Menu";
import { useCanned } from "../../../reactQuery/chat";
import { Stack, Divider } from "@mui/material";
import useCustomMutate from "../../../hooks/useMutate";
import { sendMessage } from "../../../sevices/chatApi";

function CannedMessage({ id }) {
  const { responseData: cannedMsg } = useCanned();
  const { mutate } = useCustomMutate(sendMessage, ["chat"]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const submit = (msg) => {
    mutate({
      job_id: id,
      message: msg,
      message_type: "custom",
    });
  };
  return (
    <div className="d-flex justify-content-end">
      <div className="canned-msg-div" onClick={handleClick}>
        <p className="text">Select a canned message</p>
      </div>
      <CustomMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={() => setAnchorEl(null)}
      >
        <div className="canned-msg-list">
          <Stack divider={<Divider orientation="horizontal" flexItem />}>
            {cannedMsg?.map((el, idx) => (
              <div className="canned-msg" onClick={() => submit(el)}>
                <p>{el}</p>
              </div>
            ))}
          </Stack>
        </div>
      </CustomMenu>
    </div>
  );
}

export default CannedMessage;
