import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import ChatListing from "./ChatListing";
import { useMediaQuery } from "react-responsive";

export default function AnchorTemporaryDrawer({
  toggleDrawer,
  state,
  jobId,
  params,
}) {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const isMobile = useMediaQuery({ maxWidth: "600px" });
  const list = (anchor) => (
    <Box
      sx={{
        width: isMobile ? screenWidth : 420,
      }}
      role="presentation"
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          bgcolor: "#F5F5F9",
        }}
        id="customized-dialog-title"
        className="chat-heading-text"
      >
        Message : {params.row.client_name}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={toggleDrawer(anchor, false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <ChatListing jobId={jobId} />
      </DialogContent>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
