import React from "react";
import Heading from "./components/Heading";
import ClientPage from "./components/ClientPage";
import "./client.scss";

function index() {
  return (
    <>
      <Heading />
      <ClientPage />
    </>
  );
}

export default index;
