// CustomDatePicker.js
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField, InputAdornment } from "@mui/material";
import { CalendarToday } from "@mui/icons-material";
import DatePickerIcon from "../styles/svg/date-picker.svg";
import moment from "moment";

const CustomDatePicker = ({
  label,
  value,
  onChange,
  defaultValue,
  errors,
  field,
  setValue,
  isShring,
  disabled,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue);
  return (
    <>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => {
          setSelectedDate(date);
          setValue(field.name, moment(date).format("YYYY-MM-DD"));
        }}
        className="w-100"
        defaultValue={defaultValue}
        disabled={disabled}
        minDate={new Date()}
        customInput={
          <TextField
            label={label ? label : ""}
            variant="outlined"
            fullWidth
            defaultValue={defaultValue}
            InputLabelProps={{ shrink: isShring ? true : false }}
            placeholder="t"
            {...props}
            error={!!errors[field.name]}
            helperText={errors[field.name]?.message}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={DatePickerIcon} />
                </InputAdornment>
              ),
            }}
          />
        }
        dateFormat="MM/dd/yyyy"
      />
    </>
  );
};

export default CustomDatePicker;
