import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Heading from "./components/Heading";
import InspectorJobs from "./components/InspectorJobs";
import { useCustomParams } from "../../../hooks/useCustomParams";
// import "./inspector.scss";

function InspectorSchedule() {
  const [date, setDate] = useCustomParams("date");
  const param = useParams();

  useEffect(() => {
    setDate("date", "today");
  }, [param.inspectorId]);

  return (
    <div>
      <Heading />
      <InspectorJobs />
    </div>
  );
}

export default InspectorSchedule;
