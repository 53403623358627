import React from "react";
import { Button, Stack } from "@mui/material";
import { useCustomParams } from "../../../hooks/useCustomParams";
import useCustomMutate from "../../../hooks/useMutate";
import { assignInspector } from "../../../sevices/inspectorApi";
import { useSelector } from "react-redux";

function AssignButton() {
  const [inspector] = useCustomParams("inspector");
  const { mutate } = useCustomMutate(assignInspector, ["jobs", "inspector"]);
  const data = useSelector((state) => state.assignTicket.jobs);

  const handleClick = () => {
    mutate({ inspector_job: data });
  };
  return (
    <>
      <Stack
        sx={{
          justifyContent: "flex-end",
          display: "flex",
          marginTop: "24px",
        }}
        direction={"row"}
        spacing={2}
        // className="popup-footer-border"
      >
        <Button
          // type="submit"
          variant="contained"
          className={!inspector ? "capitalize h-42" : "primary-btn-class"}
          disabled={!inspector}
          sx={{ boxShadow: "none", width: "128px" }}
          onClick={handleClick}
        >
          Assign
        </Button>
      </Stack>
    </>
  );
}

export default AssignButton;
