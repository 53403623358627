import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobs: [],
};

const ticketAssign = createSlice({
  name: "ticketAssign",
  initialState,
  reducers: {
    setTickets: {
      prepare(jobs) {
        return {
          payload: { jobs },
        };
      },
      reducer(state, action) {
        state.jobs = action.payload.jobs;
      },
    },
    assignTickets: {
      prepare(job) {
        return {
          payload: { job },
        };
      },
      reducer(state, action) {
        state.jobs = state.jobs.map((el) => {
          const isChange = action.payload.job.job_id == el.job_id;
          if (isChange) {
            return {
              ...el,
              inspector_id: action.payload.job.inspector_id,
            };
          } else {
            return el;
          }
        });
      },
    },
  },
});

export const { setTickets, assignTickets } = ticketAssign.actions;

export default ticketAssign.reducer;
