import moment from "moment";
// import EditIcon from "./EditIcon";
import { formatPhoneNumber } from "../../../utils/helpers";
import Action from "./Action";
import ClientName from "./ClientName";

export const columns = [
  {
    field: "company_name",
    headerName: "Client",
    renderCell: (params) => {
      return <ClientName params={params} />;
    },
    minWidth: 140,
    flex: 1,
    // editable: true,
    // sortable: false,
  },
  {
    field: "manager",
    headerName: "Manager",
    renderCell: (params) => {
      return <p className="">{params.row.user.name}</p>;
    },
    minWidth: 140,
    flex: 1,
    // editable: true,
  },
  {
    field: "email",
    headerName: "Email Address",
    renderCell: (params) => {
      return <p className="">{params.row.user.email}</p>;
    },
    minWidth: 230,
    flex: 1.2,
    // editable: true,
    sortable: false,
  },
  {
    field: "phone_number",
    headerName: "Phone Number",
    renderCell: (params) => {
      return <p>{formatPhoneNumber(params.row.user.phone_number || "")}</p>;
    },
    minWidth: 130,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "action",
    headerName: "Action",
    renderCell: (params) => {
      return (
        <div className="d-flex">
          <Action params={params} />
        </div>
      );
    },
    minWidth: 270,
    flex: 1.2,
    // editable: true,
    sortable: false,
  },
];
