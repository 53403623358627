import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
// import { useDispatch } from "react-redux";

import SearchIcon from "../../styles/svg/search-icon.svg";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchTicket } from "../../redux/ticketSlice";
// import { searchCard } from "../../redux/SearchSlice";
// import { useSelector } from "react-redux";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  //   backgroundColor: alpha(theme.palette.common.white, 0.15),
  //   "&:hover": {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  //   },
  marginLeft: 0,

  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    // width: "200px",
    height: "36px",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  color: " rgba(145, 158, 171, 1)",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: " rgba(145, 158, 171, 1)",
  // width: isMobile ? "70%" : "100%",
  borderRadius: "6px",
  border: "1px solid rgba(145, 158, 171, 0.12)",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    fontSize: "15px",

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      // width: "200px",
      height: "26px",
      "&:focus": {
        // width: "200px",
      },
    },
    "&::placeholder": {
      color: "#919EAB", // Placeholder color
    },
  },
}));

function GlobalSearch() {
  const isMobile = useMediaQuery({ maxWidth: "600px" });
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get("search");
  const [searchInput, setSearchInput] = useState(searchText);

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const dispatch = useDispatch();
  const searchVal = useSelector((state) => state.ticket.search);

  function handleChange(event) {
    updateSearchParams("search", event.target.value);
    setSearchInput(event.target.value);
    // dispatch(searchTicket(event.target.value));
  }
  return (
    <div
      className={`${isMobile ? "w-170px" : "w-200px"}`}
      // onKeyDown={(e) => e.preventDefault()}
    >
      <Search>
        <SearchIconWrapper>
          <img src={SearchIcon} />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          className="search-filter"
          inputProps={{ "aria-label": "search" }}
          value={searchInput}
          // defaultValue={searchText}
          onChange={handleChange}
          type="search"
        />
      </Search>
    </div>
  );
}

export default GlobalSearch;
