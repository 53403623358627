// App.js
import React, { useState } from "react";
import { Container } from "@mui/material";
import TicketForm from "./TicketForm";

const CreateTicket = ({ onClose, isFromClient = false }) => {
  const [formData, setFormData] = useState({
    scheduled_date: "",
    service_id: "",
    building_id: "",
    apartment: "",
    unit_id: "",
    unit_status: "",
    borough: "",
    superintendent_name: "",
    superintendent_number: "",
    additional_comment: "",
    is_flexible: false,
  });

  return (
    <Container>
      <div className="mb-32">
        <p className="schedule-appointment-heading">Schedule Appointment</p>
        <p className="form-instruction">
          Please complete all fields to schedule an appointment for service.
        </p>
      </div>
      <TicketForm
        initialValues={formData}
        onClose={onClose}
        isFromClient={isFromClient}
      />
    </Container>
  );
};

export default CreateTicket;
