import React from "react";
import Heading from "./components/Heading";
import InspectorAssign from "./components/InspectorAssign";
import "./inspector.scss";

function index() {
  return (
    <>
      <Heading />
      <InspectorAssign />
    </>
  );
}

export default index;
