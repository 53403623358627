import React from "react";
import RightFilters from "./RightFilters";

function Filters() {
  return (
    <div className="tickets-filter d-flex align-items-center justify-content-space-between">
      <p className="secondary-heading-text">All Tickets</p>
      <RightFilters />
    </div>
  );
}

export default Filters;
