import React, { useState } from "react";
import InspectorForm from "./InspectorForm";
import { formatPhoneNumber } from "../../../utils/helpers";

function EditInspector({ onClose, data }) {
  const [formData, setFormData] = useState({
    email: data.user.email,
    // password: data.user.password,
    name: data.user.name,
    phone_number: formatPhoneNumber(data.user.phone_number),
    certificate_id: data.certificate_id,
    certification_expire_date: data.certification_expire_date,
    has_permission: data.has_permission,
    xrf_devices: data.xrf_devices,
    id: data.inspector_id,
  });
  return (
    <div>
      <InspectorForm initialValues={formData} onClose={onClose} isEdit={true} />
    </div>
  );
}

export default EditInspector;
