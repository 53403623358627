import { useQuery } from "@tanstack/react-query";
import { getBuilding, getBuildingById } from "../sevices/buildingApi";

export function useBuildings(keys) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["building"],
    queryFn: () => getBuilding(keys),
  });

  const responseData =
    data?.data?.data?.response_data?.map((el, idx) => {
      return {
        ...el,
        id: idx + 1,
      };
    }) || [];
  const totalRecords = data?.data?.data?.total_records;
  const unitStatus = data?.data?.data?.unit_status_list || [];

  return {
    isLoading,
    error,
    responseData,
    unitStatus,
    isError,
    refetch,
    totalRecords,
  };
}

export function useClientBuildings(keys, id) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["building", id],
    queryFn: () => getBuilding(keys),
  });

  const responseData =
    data?.data?.data?.response_data?.map((el, idx) => {
      return {
        ...el,
        id: idx + 1,
      };
    }) || [];
  const totalRecords = data?.data?.data?.total_records;
  const unitStatus = data?.data?.data?.unit_status_list || [];

  return {
    isLoading,
    error,
    responseData,
    unitStatus,
    isError,
    refetch,
    totalRecords,
  };
}

export function useBuildingsById(id) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["units", id],
    queryFn: () => getBuildingById(id),
  });

  const responseData =
    data?.data?.data?.response_data?.unit?.map((el, idx) => {
      return {
        ...el,
        id: idx + 1,
      };
    }) || [];
  const totalRecords = data?.data?.data?.total_records;
  const detail = data?.data?.data?.response_data?.building_detail;

  return {
    isLoading,
    error,
    responseData,
    detail,
    isError,
    refetch,
    totalRecords,
  };
}
