import React from "react";
import {
  TextField,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Stack,
  Typography,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/system";
import useCustomForm from "../../../hooks/useForm";
import { useReportOptions } from "../../../reactQuery/job";
import useCustomMutate from "../../../hooks/useMutate";
import { uploadReport } from "../../../sevices/jobApi";
import { objectToFormData } from "../../../utils/helpers";
// import Validations from "../../../schemas/statusReport";

const InputField = styled("input")({
  display: "none",
});

const UploadButton = styled(Button)(({ theme }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  boxShadow: "none",
}));

function ReportUploadForm({ onClose, params }) {
  const { responseData: reportOptions } = useReportOptions();
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    handleReset,
    setValue,
    watch,
  } = useCustomForm({ report_status: params.row?.report_status || "" });
  const { mutate } = useCustomMutate(uploadReport, ["jobs"], onClose);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setValue("file", file);
  };
  const submit = (data) => {
    mutate({ data: objectToFormData(data), id: params.row.job_id });
  };
  const fileDocument = watch("file");
  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <Stack spacing={2}>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <InputLabel shrink>Report Upload</InputLabel>
              <label htmlFor="upload-file">
                <TextField
                  accept="*"
                  id="upload-file"
                  type="file"
                  {...register("file")}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  error={errors?.file ? true : false}
                />
                <UploadButton
                  variant="outlined"
                  component="span"
                  className="upload-btn"
                >
                  {fileDocument && fileDocument.name
                    ? fileDocument.name
                    : "Upload"}
                </UploadButton>
              </label>
            </FormControl>
            <FormHelperText sx={{ color: "red" }}>
              {errors?.file && errors.file.message}
            </FormHelperText>
          </Grid>
          <Grid>
            <FormControl fullWidth error={errors?.report_status ? true : false}>
              <InputLabel>Status</InputLabel>
              <Select
                {...register("report_status", {
                  required: `Report Status is required`,
                })}
                // defaultValue={initialValues[field.name] || ""}
                error={errors?.report_status ? true : false}
                helperText={
                  errors?.report_status && errors.report_status.message
                }
                className="select-box"
                defaultValue={params.row?.report_status}
              >
                {reportOptions?.map((option, idx) => (
                  <MenuItem
                    value={option}
                    key={idx}
                    className="dropdown-menu-options"
                  >
                    <p className="dropdown-options">{option}</p>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {errors?.report_status && errors.report_status.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          {/* <Grid className="d-flex justify-content-end "> */}
          <Stack
            sx={{
              alignSelf: "end",
            }}
            direction={"row"}
            spacing={2}
            className="popup-footer-border"
          >
            <Button
              variant="outlined"
              className="secondary-btn-class"
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="contained"
              className=" primary-btn-class"
              // disabled={checkedTerms}
              sx={{ boxShadow: "none" }}
            >
              Submit
            </Button>
          </Stack>
          {/* </Grid> */}
        </Stack>
      </form>
    </div>
  );
}

export default ReportUploadForm;
