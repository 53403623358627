import React from "react";
import Heading from "./components/Heading";
import Table from "./components/Table";

function InspectorRole() {
  return (
    <div>
      <Heading />
      <Table />
    </div>
  );
}

export default InspectorRole;
