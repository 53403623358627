import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: "",
};

const ticket = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    searchTicket: {
      prepare(search) {
        return {
          payload: { search },
        };
      },
      reducer(state, action) {
        state.search = action.payload.search;
      },
    },
  },
});

export const { searchTicket } = ticket.actions;

export default ticket.reducer;
