import React, { useEffect, useState } from "react";
import { columns } from "./tableColumns";
import { useCustomParams } from "../../../../../hooks/useCustomParams";
import { useJobs } from "../../../../../reactQuery/job";
import Loader from "../../../../../components/Loader";
import CustomTable from "../../../../../components/Table/CustomTable";

function Table() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [status] = useCustomParams("status");
  const [search] = useCustomParams("search");
  const [date] = useCustomParams("date");
  const encryptDate = JSON.parse(date);

  const key = status
    ? status == "Flexible"
      ? "is_flexible=true"
      : status == "Not Uploaded"
      ? "report_available=false"
      : `status=${status}`
    : "";
  const {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    reportColours,
  } = useJobs({
    page: page + 1,
    pageSize,
    status: key,
    search,
    date: encryptDate,
    pagination: true,
  });

  useEffect(() => {
    refetch();
  }, [page, pageSize, status, search, date]);

  // console.log(columns);

  return (
    <>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <CustomTable
          initialRows={responseData}
          columns={
            status == "Pending"
              ? columns.filter(
                  (el) =>
                    el.field != "report_status" && el.field != "complete-action"
                )
              : columns.filter((el) => el.field != "pending-action")
          }
          // defaultSort={"scheduled_date"}
          headerBgColor={"#F5F5F9"}
          rowCount={totalRecords}
          setPaginationModel={(data) => {
            setPage(data.page);
            setPageSize(data.pageSize);
          }}
          page={page}
          pageSize={pageSize}
          subHeight={302}
        />
      )}
    </>
  );
}

export default Table;
