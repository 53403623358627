import React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import SearchIcon from "../../styles/svg/search-icon.svg";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  //   backgroundColor: alpha(theme.palette.common.white, 0.15),
  //   "&:hover": {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  //   },
  marginLeft: 0,

  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  color: " rgba(145, 158, 171, 1)",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: " rgba(145, 158, 171, 1)",
  width: "96%",
  borderRadius: "6px",
  border: "1px solid rgba(145, 158, 171, 0.12)",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    fontSize: "15px",

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      //   width: "20ch",
      height: "20px",
    },
    "&::placeholder": {
      color: "#919EAB", // Placeholder color
    },
  },
}));

function SearchList({ onSearch, searchQuery }) {
  function handleChange(event) {
    onSearch(event.target.value);
  }
  return (
    <div onKeyDown={(e) => e.stopPropagation()}>
      <Search>
        <SearchIconWrapper>
          <img src={SearchIcon} />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={searchQuery}
          onChange={handleChange}
          type="search"
        />
      </Search>
    </div>
  );
}

export default SearchList;
