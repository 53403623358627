import React from "react";
import Protected from "./Protected";
import Login from "../../pages/Auth/Login";
import SignUp from "../../pages/Auth/SignUp";
import Forgot from "../../pages/Auth/Forgot";
import Reset from "../../pages/Auth/Reset";

export const Auth = {
  element: <Protected />,
  //   errorElement: <RouterErrorPage />,
  children: [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/sign-up",
      element: <SignUp />,
    },
    {
      path: "/forgot-password",
      element: <Forgot />,
    },
    {
      path: "/reset-password",
      element: <Reset />,
    },
  ],
};
