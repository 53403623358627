import Api from "./axios";

export const getInspector = async (data) => {
  const prepareDateRangeQuery = data?.date
    ? `&start_date=${data?.date.startDate}&end_date=${data?.date.endDate}`
    : "";
  return await Api.get(
    `/v1/inspector?page=${data?.page || 1}&page_size=${
      data?.pageSize || 100
    }&search=${data?.search || ""}&pagination=${
      data?.pagination || "false"
    }${prepareDateRangeQuery}`
  );
};

export const assignInspector = async (data) => {
  return await Api.put(`/v1/job/assign-inspector`, data);
};

export const createInspector = async (data) => {
  return await Api.post(`/v1/inspector`, data);
};

export const updateInspector = async ({ id, data }) => {
  return await Api.put(`/v1/inspector/${id}`, data);
};
