import React, { useState } from "react";
import RoleRoutes from "./components/RoleRoutes";

function Dashboard() {
  return (
    <>
      <RoleRoutes />
    </>
  );
}

export default Dashboard;
