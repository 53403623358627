import { useQuery } from "@tanstack/react-query";
import { getTerms } from "../sevices/termsApi";

export function useTerms() {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["terms"],
    queryFn: getTerms,
  });

  const responseData = data?.data?.data;

  return { isLoading, error, responseData, isError, refetch };
}
