import React from "react";
import Heading from "./components/Heading";
import TicketPage from "./components/TicketPage";
import "./tickets.scss";

function index() {
  return (
    <>
      <Heading />
      <TicketPage />
    </>
  );
}

export default index;
