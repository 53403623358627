import { configureStore } from "@reduxjs/toolkit";
import TicketSlice from "./redux/ticketSlice";
import ticketAssignSlice from "./redux/ticketAssignSlice";

const store = configureStore({
  reducer: {
    ticket: TicketSlice,
    assignTicket: ticketAssignSlice,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(conditionMiddleware),
});

export default store;
