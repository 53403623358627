import React, { useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";

function Heading() {
  const isMobile = useMediaQuery({ maxWidth: "900px" });
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid item xs={12} md={12}>
            <p className="page-heading-name">Inspector Assign</p>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Heading;
