import React from "react";
import Filters from "./Filters";
import Table from "./Table";
import { useBuildings } from "../../../reactQuery/building";

function TicketPage() {
  const { responseData } = useBuildings();
  return (
    <div className="mt-4 ticket-page">
      <Filters />
      <Table />
    </div>
  );
}

export default TicketPage;
