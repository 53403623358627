import { Menu, MenuItem } from "@mui/material";
import React from "react";

function CustomMenu({ anchorEl, open, handleClose, children }) {
  return (
    <Menu
      id="account-menu"
      anchorEl={anchorEl}
      className="canned-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      sx={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            bottom: 0,
            right: 14,
            width: 14,
            height: 14,
            // bgcolor: "background.paper",
            transform: "translateY(50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "bottom" }}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      {children}
    </Menu>
  );
}

export default CustomMenu;
