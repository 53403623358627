import React from "react";
import {
  TextField,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Stack,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import SelectDatePicker from "../../../components/SelectDatePicker";
import { useInspector } from "../../../reactQuery/inspector";
import { useCustomParams } from "../../../hooks/useCustomParams";
import { useRemoveAllFilters } from "../../../hooks/useRemoveAllFilters";

const CustomSelect = styled(Select)({
  height: "36px", // or your desired height
  display: "flex",
  alignItems: "center",
  ".MuiSelect-select": {
    paddingTop: 0,
    paddingBottom: 0,
    display: "flex",
    alignItems: "center",
  },
  ".MuiOutlinedInput-input": {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

function RightFilters() {
  const { responseData: inspectors } = useInspector();
  const [inspector, updateSearchParams, removeSearchParam] =
    useCustomParams("inspector");
  const [date] = useCustomParams("date");
  const [removeAll] = useRemoveAllFilters();
  const removeFilter = () => {
    removeAll(["date", "inspector"]);
  };
  return (
    <div className="d-flex align-items-center">
      {/* <div>
        {inspector || date ? (
          <p className="clear-filter" onClick={removeFilter}>
            Clear Filter
          </p>
        ) : (
          ""
        )}
      </div> */}
      <div>
        <SelectDatePicker />
      </div>
      <div className="assign-inspector-drop-down">
        <FormControl fullWidth sx={{ width: "149px" }}>
          <Select
            defaultValue={inspector || ""}
            className="select-box"
            displayEmpty
          >
            <MenuItem disabled value="">
              <em
                className="header-action-button-text"
                style={{ fontStyle: "normal", fontSize: "14px" }}
              >
                Select Inspector
              </em>
            </MenuItem>

            {inspectors
              ?.filter((el) => el.has_permission)
              ?.map((option, idx) => (
                <MenuItem
                  value={option.inspector_id}
                  key={idx}
                  className="dropdown-menu-options"
                  onClick={() =>
                    updateSearchParams("inspector", option.inspector_id)
                  }
                >
                  <p className="dropdown-options">
                    {option.user.name}
                    <span className="assign-jobs">
                      {" "}
                      ({option.inspector_job})
                    </span>
                  </p>
                </MenuItem>
              )) || []}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

export default RightFilters;
