import React, { useState } from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import SendIcon from "../../../styles/svg/send-icon.svg";
import useCustomMutate from "../../../hooks/useMutate";
import { sendMessage } from "../../../sevices/chatApi";

function SendMessage({ id }) {
  const [msg, setMsg] = useState("");
  const { mutate } = useCustomMutate(sendMessage, ["chat"], () => setMsg(""));

  const submit = () => {
    if (msg) {
      mutate({
        job_id: id,
        message: msg,
        message_type: "custom",
      });
    }
  };
  return (
    <div>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        name="email"
        value={msg}
        autoComplete="email"
        onChange={(e) => setMsg(e.target.value)}
        onKeyDown={(e) => e.key == "Enter" && submit()}
        autoFocus
        className="primary-input-field"
        placeholder="Start typing..."
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={submit}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <img src={SendIcon} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default SendMessage;
