import Api from "./axios";

export const getChats = async (id) => {
  return await Api.get(`/v1/message/job/${id}`);
};

export const getCannedMessage = async () => {
  return await Api.get(`/v1/message/`);
};

export const sendMessage = async (data) => {
  return await Api.post(`/v1/message/send`, data);
};
