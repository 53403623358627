// import EditIcon from "./EditIcon";
// import ReportUpload from "./ReportUpload";

import { formatPhoneNumber } from "../../../utils/helpers";
import EditIcon from "./EditIcon";
import InspctorName from "./InspctorName";

export const columns = [
  //   {
  //     field: "scheduled_date",
  //     headerName: "Scheduled date",
  //     renderCell: (params) => {
  //       return <p>{moment(params.value).local().format("MM/DD/YYYY")}</p>;
  //     },
  //     minWidth: 130,
  //     flex: 1,
  //     // editable: true,
  //     sortable: false,
  //   },
  {
    field: "name",
    headerName: "Name",
    renderCell: (params) => {
      return <InspctorName params={params} />;
    },
    minWidth: 100,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    renderCell: (params) => {
      return <p>{params.row.user.email}</p>;
    },
    minWidth: 100,
    flex: 1.5,
    // editable: true,
    sortable: false,
  },
  {
    field: "phone_number",
    headerName: "Phone No.",
    renderCell: (params) => {
      return <p>{formatPhoneNumber(params.row.user.phone_number)}</p>;
    },
    minWidth: 100,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "xrf_devices",
    headerName: "XRF Device#",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 80,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "certification_expire_date",
    headerName: "Certification Expiration Date",
    renderCell: (params) => {
      return <p>{params.value}</p>;
    },
    minWidth: 100,
    flex: 1.2,
    // editable: true,
    sortable: false,
  },
  {
    field: "action",
    headerName: "Action",
    renderCell: (params) => {
      return <EditIcon params={params} />;
    },
    minWidth: 150,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  //     minWidth: 70,
  //     flex: 0.7,
  //     // editable: true,
  //     sortable: false,
  //     headerClassName: "center-header",
  //   },
];
