import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import RedFlag from "../../../../../styles/svg/red-unit.svg";
import GreenFlag from "../../../../../styles/svg/green-unit.svg";
import frictionRed from "../../../../../styles/svg/red-friction.svg";
import frictionGreen from "../../../../../styles/svg/green-friction.svg";
import DefaultServiceIcon from "../../../../../styles/svg/default-service-icon.svg";
import CustomTable from "../../../../../components/Table/CustomTable";
import Loader from "../../../../../components/Loader";
import { useCustomParams } from "../../../../../hooks/useCustomParams";
import { servicesVariables } from "../../../../../utils/constants";
import useDownloader from "react-use-downloader";
import { downloadReport } from "../../../../../sevices/jobApi";

function Table({
  responseData,
  totalRecords,
  isLoading,
  isError,
  error,
  detail,
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(4);
  const [search] = useCustomParams("search");
  const redirect = useNavigate();

  const { download } = useDownloader();

  const downloadFile = (id) => {
    downloadReport(id)
      .then((res) => {
        console.log(res);
        download(
          `${process.env.REACT_APP_BASE_URL}${res.data.data.file_url}`,
          res.data.data.file_name
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      field: "unit_number",
      headerName: "#Unit",
      renderCell: (params) => {
        return (
          <p
            className="unit-ticket cursor-pointer"
            onClick={() => redirect(`unit/${params.row.unit_id}`)}
          >
            {params.value}
          </p>
        );
      },
      minWidth: 100,
      flex: 1,
      // editable: true,
      sortable: false,
    },
    {
      field: "building_address",
      headerName: "Building",
      renderCell: (params) => {
        return <p className="">{detail?.address}</p>;
      },
      minWidth: 110,
      flex: 1,
      // editable: true,
      sortable: false,
    },
    {
      field: "job_data",
      headerName: "Report On File",
      renderCell: (params) => {
        return (
          <div className="w-100 d-flex justify-content-center">
            {params?.value?.service_id && params.row?.job_data?.attachment ? (
              params?.value?.service_id ==
                servicesVariables.xrfEnitrUnitNegative &&
              params.row.job_data.report_status == "Negative" ? (
                <img
                  src={GreenFlag}
                  className="cursor-pointer"
                  onClick={() => downloadFile(params.row.job_data.job_id)}
                />
              ) : params?.value?.service_id ==
                  servicesVariables.xrfEnitrUnitPositive &&
                params.row.job_data.report_status == "Positive" ? (
                <img
                  src={RedFlag}
                  className="cursor-pointer"
                  onClick={() => downloadFile(params.row.job_data.job_id)}
                />
              ) : params?.value?.service_id ==
                  servicesVariables.xrfFrictionPositive &&
                params.row.job_data.report_status == "Positive" ? (
                <img
                  src={frictionRed}
                  className="cursor-pointer"
                  onClick={() => downloadFile(params.row.job_data.job_id)}
                />
              ) : params?.value?.service_id ==
                  servicesVariables.xrfFrictionNegative &&
                params.row.job_data.report_status == "Negative" ? (
                <img
                  src={frictionGreen}
                  className="cursor-pointer"
                  onClick={() => downloadFile(params.row.job_data.job_id)}
                />
              ) : (
                <img
                  src={DefaultServiceIcon}
                  className="cursor-pointer"
                  onClick={() => downloadFile(params.row.job_data.job_id)}
                />
              )
            ) : (
              ""
            )}
          </div>
        );
      },
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Report On File</p>
        </div>
      ),
      minWidth: 110,
      flex: 1,
      // editable: true,
      sortable: false,
      headerClassName: "center-header",
    },
  ];
  const filteredUnits = search
    ? responseData?.filter((item) =>
        item.unit_size.toLowerCase().includes(search.toLowerCase())
      )
    : responseData;

  return (
    <>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <>
          <CustomTable
            initialRows={filteredUnits}
            columns={columns}
            // defaultSort={"job_id"}
            headerBgColor={"#F5F5F9"}
            rowCount={totalRecords}
            setPaginationModel={(data) => {
              setPage(data.page);
              setPageSize(data.pageSize);
            }}
            page={page}
            pageSize={pageSize}
            subHeight={282}
            hideFooter={true}
            useMaxHeight={true}
          />
        </>
      )}
    </>
  );
}

export default Table;
