import React, { useEffect, useState } from "react";

import { useCustomParams } from "../../../../hooks/useCustomParams";
import { useJobs } from "../../../../reactQuery/job";
import { columns } from "./tableColumns";
import CustomTable from "../../../../components/Table/CustomTable";
import Loader from "../../../../components/Loader";
import { useParams } from "react-router-dom";
import moment from "moment";

function Table() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [status] = useCustomParams("status");
  const [dateFilter] = useCustomParams("date");
  const { inspectorId } = useParams();
  const key = status
    ? status == "Flexible"
      ? "is_flexible=true"
      : status == "Not Uploaded"
      ? "report_available=false"
      : `status=${status}`
    : "";

  const date = dateFilter
    ? {
        startDate:
          dateFilter == "today"
            ? moment().format("YYYY-MM-DD")
            : dateFilter == "thisWeek"
            ? moment().startOf("week").format("YYYY-MM-DD")
            : moment().startOf("month").format("YYYY-MM-DD"),
        endDate:
          dateFilter == "today"
            ? moment().format("YYYY-MM-DD")
            : dateFilter == "thisWeek"
            ? moment().endOf("week").format("YYYY-MM-DD")
            : moment().endOf("month").format("YYYY-MM-DD"),
      }
    : "";

  const { isLoading, error, responseData, isError, refetch, totalRecords } =
    useJobs({
      page: page + 1,
      pageSize,
      status: key,
      pagination: true,
      inspector: inspectorId,
      assign: `&inspector_assign=true`,
      date,
    });

  useEffect(() => {
    refetch();
  }, [page, pageSize, status, inspectorId, dateFilter]);
  return (
    <>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <CustomTable
          initialRows={responseData}
          columns={columns}
          // defaultSort={"scheduled_date"}
          headerBgColor={"#F5F5F9"}
          rowCount={totalRecords}
          setPaginationModel={(data) => {
            setPage(data.page);
            setPageSize(data.pageSize);
          }}
          page={page}
          pageSize={pageSize}
          subHeight={302}
        />
      )}
    </>
  );
}

export default Table;
