import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Stack,
  FormHelperText,
  InputAdornment,
} from "@mui/material";

import CustomDatePicker from "../../../components/DatePicker";
import useCustomForm from "../../../hooks/useForm";
import useCustomMutate from "../../../hooks/useMutate";
import {
  createInspector,
  updateInspector,
} from "../../../sevices/inspectorApi";
import Switch from "../../services/components/Switch";
import { deFormatPhoneNumber, formatPhoneNumber } from "../../../utils/helpers";

function InspectorForm({
  initialValues,
  onClose,
  isEdit,
  isInspector = false,
}) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    handleReset,
    setValue,
    watch,
    setError,
    clearErrors,
    isValid,
  } = useCustomForm(initialValues);
  const { mutate } = useCustomMutate(
    isEdit ? updateInspector : createInspector,
    ["inspector"],
    (data) => {
      onClose();
      isInspector && updateLocalStorage(data);
    }
  );
  const userData = JSON.parse(localStorage.getItem("userData"));

  const fields = [
    {
      name: "name",
      label: "Name",
      type: "text",

      placeholder: "Enter Name",
    },
    {
      name: "email",
      label: "Email Address",
      type: "text",

      placeholder: "Enter Email",
    },
    ...(isEdit
      ? []
      : [
          {
            name: "password",
            label: "Password",
            type: "password",

            placeholder: "Enter Password",
          },
        ]),

    {
      name: "phone_number",
      label: "Phone Number",
      type: "number",

      placeholder: "Enter Phone",
    },
    {
      name: "xrf_devices",
      label: "XRF Device#",
      type: "text",

      placeholder: "Enter XRF Device#",
    },
    {
      name: "certificate_id",
      label: "Certification Id#",
      type: "text",
      disabled: isInspector,
      placeholder: "Enter Id",
    },

    {
      name: "certification_expire_date",
      label: "Certification Expire Date",
      type: "date",
      disabled: isInspector,
      placeholder: "Select date",
    },
  ];
  const submit = (data) => {
    setLoading(true);
    const prepareDate = isEdit
      ? {
          data: {
            email: data.email,
            password: data.password,
            name: data.name,
            phone_number: deFormatPhoneNumber(data.phone_number),
            xrf_devices: data.xrf_devices,
            certificate_id: data.certificate_id,
            certification_expire_date: data.certification_expire_date,
            has_permission: data.has_permission,
          },
          id: initialValues.id,
        }
      : {
          email: data.email,
          password: data.password,
          name: data.name,
          phone_number: deFormatPhoneNumber(data.phone_number),
          xrf_devices: data.xrf_devices,
          certificate_id: data.certificate_id,
          certification_expire_date: data.certification_expire_date,
          has_permission: true,
        };
    console.log("inspector form fields", prepareDate);
    mutate(prepareDate);
  };

  const updateLocalStorage = (data) => {
    const prepareData = {
      ...userData,
      email: data.data.inspector.email,
      name: data.data.inspector.name,
      phone_number: data.data.inspector.phone_number,
      inspector_detail: {
        ...userData.inspector_detail,
        xrf_devices: data.data.inspector.xrf_devices,
      },
    };
    console.log("resdata", data.data.inspector, prepareData);
    localStorage.setItem("userData", JSON.stringify(prepareData));
  };

  const changeStatus = (status) => {
    setValue("has_permission", status);
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack>
        <Grid container spacing={4}>
          {fields.map((field, index) => (
            <Grid item xs={12} md={12} key={index}>
              {field.type === "date" ? (
                <CustomDatePicker
                  label={field.label}
                  defaultValue={initialValues[field.name] || ""}
                  errors={errors}
                  field={field}
                  setValue={setValue}
                  disabled={field.disabled || false}
                  {...register(field.name, {
                    required: `${field.label} is required`,
                  })}
                  isShring={true}
                  placeholder={field.placeholder}
                />
              ) : field.type === "password" ? (
                <TextField
                  label={field.label}
                  type="password"
                  autoComplete="new-password"
                  fullWidth
                  {...register(field.name, {
                    required: `${field.label} is required`,
                  })}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              ) : field.type === "number" ? (
                <TextField
                  label={field.label}
                  fullWidth
                  {...register(field.name, {
                    required: `${field.label} is required`,
                  })}
                  onChange={(e) => {
                    setValue("phone_number", formatPhoneNumber(e.target.value));
                  }}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              ) : (
                <TextField
                  label={field.label}
                  fullWidth
                  {...register(field.name, {
                    required: `${field.label} is required`,
                  })}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              )}
            </Grid>
          ))}
          {isEdit && !isInspector ? (
            <>
              {/* <Grid container className="status-grid"> */}
              <Grid item xs={3} sm={2} md={2}>
                <p className="status-text">Status</p>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Switch
                  callback={changeStatus}
                  defaultValue={initialValues.has_permission}
                />
              </Grid>

              {/* </Grid> */}
            </>
          ) : (
            ""
          )}
        </Grid>
        {/* <Grid className="d-flex justify-content-end "> */}
        <Stack
          sx={{
            alignSelf: "end",
          }}
          direction={"row"}
          spacing={2}
          className="popup-footer-border"
        >
          <Button
            variant="outlined"
            className="secondary-btn-class"
            onClick={onClose}
            // disabled={loading}
          >
            Cancel
          </Button>
          {/* //capitalize h-42 */}
          <Button
            type="submit"
            variant="contained"
            className={"primary-btn-class"}
            // disabled={loading}
            sx={{ boxShadow: "none" }}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
      {/* </Grid> */}
    </form>
  );
}

export default InspectorForm;
