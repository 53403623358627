import React from "react";
import Filters from "./Filters";
import { useClient } from "../../../reactQuery/client";
import Table from "./Table";

function ClientPage() {
  useClient();
  return (
    <div className="mt-4 ticket-page">
      <Filters />
      <Table />
    </div>
  );
}

export default ClientPage;
