import React, { useState } from "react";
import ChatIcon from "../../../styles/svg/tabler_message.svg";
import AnchorTemporaryDrawer from "./Drawer";
import "./chat.scss";

function Chat({ params }) {
  const [chatDialog, setChatDialog] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setChatDialog({ ...chatDialog, [anchor]: open });
  };
  return (
    <>
      <AnchorTemporaryDrawer
        state={chatDialog}
        toggleDrawer={toggleDrawer}
        jobId={params.row.job_id}
        params={params}
      />
      <img
        src={ChatIcon}
        onClick={() => setChatDialog({ ...chatDialog, right: true })}
        className="cursor-pointer"
      />
    </>
  );
}

export default Chat;
