import React from "react";
import { useNavigate } from "react-router-dom";

function InspctorName({ params }) {
  const redirect = useNavigate();
  return (
    <>
      <p
        className="cursor-pointer underline"
        onClick={() =>
          redirect(`/inspector/${params.row.inspector_id}?date=today`)
        }
      >
        {params.row.user.name}
      </p>
    </>
  );
}

export default InspctorName;
