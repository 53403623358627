import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, Stack, TextField, Grid } from "@mui/material";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import ColonIcon from "../../../styles/svg/colon-icon.svg";
import DatePickerComponent from "../../../components/DatePickerComponent";
import CustomDatePicker from "../../../components/DatePicker";
import useCustomForm from "../../../hooks/useForm";
import NumberInput from "./TicketsNumber";
import ToggleInput from "../../../components/ToggleInput";
import useCustomMutate from "../../../hooks/useMutate";
import { getCalendar, updateCalendar } from "../../../sevices/calendarApi";
import moment from "moment/moment";
import { useMediaQuery } from "react-responsive";

function SchedulingCalendarForm({ onClose, data }) {
  const isMobile = useMediaQuery({ maxWidth: "900px" });
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    handleReset,
    setValue,
    watch,
  } = useCustomForm(data);
  const { mutate } = useCustomMutate(updateCalendar, ["calendar"], onClose);
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const queryClient = useQueryClient();

  const date = watch("scheduled_date");
  const is_blocked = watch("is_blocked");

  useEffect(() => {
    console.log(date);
    // getCalendar({ startDate: date, endDate: date, time_zone: "" })
    //   .then((el) => console.log(el))
    //   .catch((err) => console.log(err));
  }, [date]);

  const submit = (data) => {
    /**
     * Create Board
     */
    setApiError("");
    setLoading(true);
    console.log("data", data);

    mutate({
      ...data,
      scheduled_date: moment(data.scheduled_date).format("YYYY-MM-DD"),
    });
  };
  return (
    // <FormProvider {...methods}>
    <Box
      component="form"
      onSubmit={handleSubmit(submit)}
      noValidate
      sx={{
        // padding: "14px",
        flexGrow: 1,
      }}
    >
      <Stack spacing={1}>
        {apiError && <Alert severity="error">{apiError}</Alert>}
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid
            item
            xs={12}
            md={5}
            className={`${
              isMobile ? "" : "justify-content-space-between"
            } d-flex align-items-center  pt-0 pl-0`}
          >
            <p className="label-calendar-form">Date</p>
            <img src={ColonIcon} />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            className={` ${isMobile ? "pl-0" : "pt-0"}`}
          >
            <p className="calendar-form-value">
              {moment(data?.scheduled_date).format("MM/DD/YYYY")}
            </p>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid
            item
            xs={12}
            md={5}
            className={`${
              isMobile ? "" : "justify-content-space-between pt-0"
            } d-flex align-items-center   pl-0`}
          >
            <p className="label-calendar-form">Max no. of tickets</p>
            <img src={ColonIcon} />
          </Grid>
          <Grid item xs={12} md={7} className={` ${isMobile ? "pl-0" : ""}`}>
            <NumberInput
              setNumber={setValue}
              defaultValue={data?.job_limit}
              is_blocked={is_blocked}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid
            item
            xs={12}
            md={5}
            className={`${
              isMobile ? "" : "justify-content-space-between pt-0"
            } d-flex align-items-center   pl-0`}
          >
            <p className="label-calendar-form">Block/Unblock day</p>
            <img src={ColonIcon} />
          </Grid>
          <Grid item xs={12} md={7} className={` ${isMobile ? "pl-0" : ""}`}>
            <ToggleInput
              setValue={setValue}
              defaultValue={data?.is_blocked || false}
              name={"is_blocked"}
            />
          </Grid>
        </Grid>

        <Stack
          sx={{
            alignSelf: "end",
          }}
          direction={"row"}
          spacing={2}
          className="popup-footer-border"
        >
          <Button
            variant="outlined"
            className="secondary-btn-class"
            onClick={onClose}
            disabled={loading}
            sx={{ boxShadow: "none" }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            // sx={{
            //   bgcolor: "#ED7047",
            //   ml: "10px",
            //   fontSize: "16px",
            //   padding: "8px 14px",
            //   fontWeight: "600",
            //   textTransform: "capitalize",
            //   minWidth: "130px",
            // }}
            className="primary-btn-class"
            sx={{ boxShadow: "none" }}
            // disabled={loading}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Box>
    // </FormProvider>
  );
}

export default SchedulingCalendarForm;
