import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import BuildingForm from "./BuildingForm";
import { formatPhoneNumber } from "../../../../../utils/helpers";

function EditBuilding({ onClose, id, data, clientName }) {
  const [formData, setFormData] = useState({
    ...data,
    client: clientName,
    apartments: data.units,
    superintendent_number: formatPhoneNumber(data.superintendent_number),
  });

  return (
    <Container>
      <BuildingForm initialValues={formData} onClose={onClose} isEdit={true} />
    </Container>
  );
}

export default EditBuilding;
