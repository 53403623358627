import React from "react";
import { Box, Grid } from "@mui/material";

function Heading() {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid item xs={12} md={12}>
            <p className="page-heading-name">My Schedule</p>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Heading;
