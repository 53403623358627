import { useQuery } from "@tanstack/react-query";
import { getServices } from "../sevices/serviceApi";

export function useServices() {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ["service"],
    queryFn: getServices,
  });

  const responseData = data?.data?.data?.response_data;

  return { isLoading, error, responseData, isError, refetch };
}
