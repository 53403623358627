import React, { useState } from "react";
import {
  Button,
  MenuItem,
  AppBar,
  Box,
  Grid,
  ListItemIcon,
  Typography,
  ListItemText,
  Menu,
  Stack,
  Avatar,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import Profile from "./Profile";
import LogoutIcon from "../../styles/svg/logout-icon.svg";
import { createAvtarName } from "../../utils/helpers";

function UserMenu() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const userData = JSON.parse(localStorage.getItem("userData"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (action) => {
    if (action === "logout") {
      localStorage.clear();
      queryClient.clear();
      navigate(`/`);
    } else {
      setAnchorEl(null);
    }
  };
  return (
    <Box>
      <Button
        id="user-profile-button"
        aria-controls={open ? "user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon className="profile-down-arrow" />}
        className="pa-0"
      >
        <Avatar sx={{ height: "36px", width: "36px" }} className="user-avatar">
          <p className="avatar-name">{createAvtarName(userData?.name)}</p>
        </Avatar>
      </Button>
      <Menu
        id="account-menu"
        className="profile-header"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Avatar
            sx={{ height: "36px", width: "36px", bgcolor: "#16304F" }}
            className="user-avtar-menu"
          >
            <p className="font-user-short capitalize">
              {createAvtarName(userData?.name)}
            </p>
          </Avatar>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography className="profile-dropdown-name">
                  {userData?.name}
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography className="profile-dropdown-email">
                  {userData?.email}
                </Typography>
              </React.Fragment>
            }
          />
        </MenuItem>
        {/* <Divider /> */}
        {userData.role == "inspector" ? <Profile Close={handleClose} /> : ""}
        <MenuItem onClick={() => handleClose("logout")}>
          <ListItemIcon>
            <img src={LogoutIcon} />
          </ListItemIcon>
          <p className="logout-text"> Logout</p>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default UserMenu;
