import React from "react";
import GlobalSearch from "../../../components/Search";
import RightFilters from "./RightFilters";

function Filters() {
  return (
    <div className="tickets-filter d-flex justify-content-space-between">
      <GlobalSearch />
      <RightFilters />
    </div>
  );
}

export default Filters;
