import Api from "./axios";

export const getBuilding = async (data) => {
  const role = JSON.parse(localStorage.getItem("userData")).role;
  const id =
    data?.clientId && role == "admin" ? `?client_id=${data.clientId}` : "";
  return await Api.get(`/v1/building${id}`);
};

export const getBuildingById = async (id) => {
  return await Api.get(`/v1/building/${id}`);
};

export const createBuilding = async (data) => {
  return await Api.post(`/v1/building`, data);
};
export const updateBuilding = async ({ data, id }) => {
  return await Api.put(`/v1/building/${id}`, data);
};
