import React, { useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import AddIcon from "@mui/icons-material/Add";

import PrimaryButton from "../../../components/Button/Button";
import FormDialog from "../../../components/Dialog/FormDialog";
import AddInspector from "./AddInspector";

function Heading() {
  const isMobile = useMediaQuery({ maxWidth: "900px" });
  const isHideText = useMediaQuery({ maxWidth: "478px" });

  const [anchorEl, setAnchorEl] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };
  return (
    <>
      {anchorEl && (
        <FormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Add Inspector"}
          btnTitle={"Confirm"}
        >
          <AddInspector onClose={handleClose} />
        </FormDialog>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid item xs={4} md={4}>
            <p className="page-heading-name">Inspectors</p>
          </Grid>
          <Grid className={`d-flex justify-content-end`} item xs={8} md={8}>
            <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 2 }}>
              <PrimaryButton
                variant="contained"
                className={`capitalize btn-bg-orange ${
                  isHideText ? "icon-m-0" : ""
                }`}
                onClick={handleClick}
                startIcon={<AddIcon />}
              >
                {isHideText ? "" : "Add Inspector"}
              </PrimaryButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Heading;
