// useForm.js
import { useForm, useFieldArray, Controller } from "react-hook-form";

const useCustomForm = (initialValues = {}, validations) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
    watch,
    setError,
    clearErrors,

    control,
  } = useForm({
    defaultValues: initialValues,
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  const handleReset = () => {
    reset(initialValues);
  };

  return {
    register,
    handleSubmit,
    Controller,
    errors,
    reset,
    onSubmit,
    handleReset,
    setValue,
    watch,
    setError,
    clearErrors,
    isValid,
    useFieldArray,
    control,
  };
};

export default useCustomForm;
