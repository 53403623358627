import React from "react";
import { Box, Grid, Stack } from "@mui/material";

import unitGreen from "../../../../../styles/svg/green-unit.svg";
import unitRed from "../../../../../styles/svg/red-unit.svg";
import frictionRed from "../../../../../styles/svg/red-friction.svg";
import frictionGreen from "../../../../../styles/svg/green-friction.svg";

function Heading({ detail }) {
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid item xs={12} md={4}>
            <p className="page-heading-name">{detail?.address}</p>
            <p className="building-info">
              Click on APT # to view All reports for that unit.
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            className="d-flex align-items-center justify-content-end"
          >
            <div className="d-flex align-items-center mr-4">
              <img src={unitGreen} className="mr-2" />
              <p className="unit-service-icons">XRF - Entire Unit Negative</p>
            </div>
            <div className="d-flex align-items-center mr-4">
              <img src={unitRed} className="mr-2" />
              <p className="unit-service-icons">XRF - Entire Unit Positive</p>
            </div>
            <div className="d-flex align-items-center mr-4">
              <img src={frictionGreen} className="mr-2" />
              <p className="unit-service-icons">XRF - Friction Negative</p>
            </div>

            <div className="d-flex align-items-center mr-4">
              <img src={frictionRed} className="mr-2" />
              <p className="unit-service-icons">XRF - Friction Positive</p>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Heading;
