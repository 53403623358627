import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Menu, Close } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { NavLink, Outlet } from "react-router-dom";

import "../dashboard.scss";
import Header from "../../../components/Header";

function Dashboard() {
  const isMobile = useMediaQuery({ maxWidth: "960px" });
  return (
    <Box>
      <Header />
      <Box className="wrapper_dashboard">
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div
                className={
                  isMobile ? "main-container-mobile " : "main-container"
                }
              >
                <Outlet />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default Dashboard;
