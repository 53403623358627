import React, { useEffect, useRef } from "react";
import { Grid } from "@mui/material";

import { useChat } from "../../../reactQuery/chat";
import Loader from "../../../components/Loader";
import ChatItem from "./ChatItem";
import SendMessage from "./SendMessage";
import CannedMessage from "./CannedMessage";

function ChatListing({ jobId }) {
  const { isLoading, error, responseData, isError, isRefetching } =
    useChat(jobId);
  const lastMessageRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [isRefetching, responseData]);

  return (
    <div>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}

      <Grid
        container
        direction="column"
        sx={{
          justifyContent: "space-between",
          alignItems: "stretch",
          height: "calc(100vh - 104px) !important",
        }}
      >
        <Grid>
          {!isLoading && !isError && (
            <div
              style={{
                height: `calc(100vh - ${
                  userData.role == "inspector" ? "240px" : "70px"
                })`,
                overflowY: "auto",
              }}
            >
              {responseData?.map((el, idx) => (
                <ChatItem key={`chat-msg-${idx}`} message={el} />
              ))}
              <div ref={lastMessageRef} />
            </div>
          )}
        </Grid>
        {userData.role == "inspector" && (
          <Grid>
            <CannedMessage id={jobId} />
            <SendMessage id={jobId} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ChatListing;
