import { useSearchParams } from "react-router-dom";

export function useCustomParams(key) {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const removeSearchParam = (key) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(key);
    setSearchParams(newSearchParams);
  };

  const data = searchParams.get(key);

  return [data, updateSearchParams, removeSearchParam];
}
