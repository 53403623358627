import {
  Button,
  MenuItem,
  AppBar,
  Box,
  Grid,
  ListItemIcon,
  Typography,
  ListItemText,
  Stack,
  Avatar,
} from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../styles/svg/raza-logo.svg";
import "./header.scss";
import { useMediaQuery } from "react-responsive";
import MobileMenu from "./MobileMenu";
import UserMenu from "./UserMenu";

export default function Default() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: "960px" });
  const role = JSON.parse(localStorage.getItem("userData"))?.role;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMobileMenu = () => {
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  const listClassName = isMobile ? "nav__list__web-r" : "nav__list__web-r";
  const linkClassName = "nav-link";
  const buttonClassName = "nav__cta-r";

  return (
    <>
      <AppBar className="app-header" position="fixed" open={true}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={2} className="d-flex">
            <div className=" d-flex">
              <img src={Logo} alt="Sofmen" />
            </div>
          </Grid>
          <Grid
            item
            xs={7}
            className={`d-flex ${isMobile ? " header-right" : ""}`}
          >
            {!isMobile && role == "admin" && (
              <>
                <ul className={`${listClassName} d-flex`}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                    <li>
                      <NavLink
                        to="/calendar"
                        className={linkClassName}
                        onClick={closeMobileMenu}
                      >
                        Calendar
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/ticket"
                        className={linkClassName}
                        onClick={closeMobileMenu}
                      >
                        Tickets
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/client"
                        className={linkClassName}
                        onClick={closeMobileMenu}
                      >
                        All Clients
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/inspector"
                        className={linkClassName}
                        onClick={closeMobileMenu}
                      >
                        Inspectors
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/service"
                        className={linkClassName}
                        onClick={closeMobileMenu}
                      >
                        Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/terms"
                        className={`${linkClassName} ${buttonClassName}`}
                        onClick={closeMobileMenu}
                      >
                        Terms
                      </NavLink>
                    </li>
                  </Stack>
                </ul>
              </>
            )}
          </Grid>

          <Grid item xs={3} className="">
            <div className="header-right">
              {isMobile && role == "admin" && <MobileMenu />}
              <UserMenu />
            </div>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
}
