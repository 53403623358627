import React from "react";
import { useNavigate } from "react-router-dom";

function BuildingName({ params }) {
  const redirect = useNavigate();
  return (
    <>
      <p
        className="cursor-pointer underline"
        onClick={() => redirect(`building/${params.row.building_id}`)}
      >
        {params.row.address}
      </p>
    </>
  );
}

export default BuildingName;
