import React from "react";
import { useNavigate } from "react-router-dom";

function ClientName({ params }) {
  const redirect = useNavigate();
  return (
    <>
      <p
        className="cursor-pointer underline"
        onClick={() => redirect(`/client/${params.row.client_id}`)}
      >
        {params.row.company_name}
      </p>
    </>
  );
}

export default ClientName;
