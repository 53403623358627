// App.js
import React, { useState } from "react";
import { Container } from "@mui/material";
import ClientForm from "./ClientForm";

const CreateClient = ({ onClose, reference }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirm_password: "",
    name: "",
    position: "",
    phone_number: "",
    company_name: "",
  });

  return (
    <Container>
      <div className="mb-32">
        <p className="schedule-appointment-heading">Client Intake Form</p>
        <p className="form-instruction">
          Thank you for choosing CompliNYC for your inspection & testing needs,
          to establish a client account. please complete the form below.
        </p>
      </div>
      <ClientForm
        initialValues={formData}
        onClose={onClose}
        reference={reference}
      />
    </Container>
  );
};

export default CreateClient;
