import moment from "moment";
import Action from "./Action";
import BuildingName from "./BuildingName";

export const columns = [
  {
    field: "address",
    headerName: "Building",
    renderCell: (params) => {
      return <BuildingName params={params} />;
    },
    minWidth: 140,
    flex: 1,
    // editable: true,
    sortable: true,
  },
  {
    field: "units",
    headerName: "#Units",
    renderCell: (params) => {
      return <p className="">{params?.value.length}</p>;
    },
    minWidth: 140,
    flex: 1,
    // editable: true,
  },

  {
    field: "action",
    headerName: "Action",
    renderCell: (params) => {
      return (
        <div className="d-flex">
          <Action params={params} />
        </div>
      );
    },
    minWidth: 100,
    flex: 0.2,
    // editable: true,
    sortable: false,
  },
];
