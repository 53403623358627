import React, { useRef, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import AddIcon from "@mui/icons-material/Add";

import PrimaryButton from "../../../components/Button/Button";
import LongFormDialog from "../../../components/Dialog/LongFormDialog";
import { useNavigate } from "react-router-dom";
import CreateClient from "./CreateClient";

function Heading() {
  const formRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: "900px" });
  const isHideText = useMediaQuery({ maxWidth: "478px" });
  const redirect = useNavigate();

  const [anchorEl, setAnchorEl] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };
  return (
    <>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Create New Client"}
          btnTitle={"Confirm"}
          formRef={formRef}
        >
          <CreateClient onClose={handleClose} reference={formRef} />
        </LongFormDialog>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid item xs={7} md={6}>
            <p className="page-heading-name">All Clients</p>
          </Grid>
          <Grid className={`d-flex justify-content-end`} item xs={5} md={6}>
            <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 2 }}>
              <PrimaryButton
                variant="contained"
                className={`capitalize btn-bg-orange ${
                  isHideText ? "icon-m-0" : ""
                }`}
                onClick={handleClick}
                startIcon={<AddIcon />}
              >
                {isHideText ? "" : "Add Client"}
              </PrimaryButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Heading;
